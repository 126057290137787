import * as React from 'react';

export const HomeIcon = (props: any) => (
  <svg width={42} height={46} viewBox="0 0 42 46" {...props}>
    <g transform="translate(-4 -2)" fill="none" fillRule="evenodd">
      <path
        d="M10.417 47.917a6.25 6.25 0 01-6.25-6.25V18.75c0-.643.296-1.25.804-1.644l18.75-14.584a2.083 2.083 0 012.558 0l18.75 14.584c.508.394.804 1.001.804 1.644v22.917a6.25 6.25 0 01-6.25 6.25H10.417zM25 6.806L8.333 19.769v21.898c0 1.15.933 2.083 2.084 2.083h6.25V25c0-1.1.853-2.002 1.934-2.078l.149-.005h12.5c1.15 0 2.083.932 2.083 2.083v18.75h6.25c1.15 0 2.084-.933 2.084-2.083V19.769L25 6.806zm4.167 20.277h-8.334V43.75h8.334V27.083z"
        fill={props.color || 'currentColor'}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
