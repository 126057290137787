import * as React from 'react';

export const CupIcon = (props: any) => (
  <svg width="53" height="53" viewBox="0 0 53 53" {...props}>
    <path
      d="M50.9916 5.2151C50.7515 4.93465 50.3914 4.7744 50.0312 4.7744H41.6679C41.6679 4.01318 41.6279 3.2119 41.5879 2.45068C41.5478 1.80965 41.0276 1.28882 40.3474 1.28882H12.1761C11.5358 1.28882 10.9756 1.80965 10.9356 2.45068C10.8956 3.2119 10.8556 4.01318 10.8556 4.7744H2.49221C2.13207 4.7744 1.77192 4.93465 1.53183 5.2151C1.29173 5.49555 1.2117 5.89619 1.29173 6.25677C2.89237 13.1879 6.09365 19.1975 10.4954 23.6045C12.0961 25.2071 13.8167 26.5693 15.6175 27.6109C17.4982 31.0965 19.7391 33.5805 22.2201 34.8625C22.5402 35.8241 22.7003 36.8657 22.7003 37.9475C22.7003 40.5516 21.6599 42.9956 20.0993 44.1574C18.1785 45.5196 17.058 47.7231 17.058 49.9667V50.0869C17.058 50.768 17.6183 51.2888 18.2985 51.2888H34.2249C34.9052 51.2888 35.4654 50.7279 35.4654 50.0869V49.9667C35.4654 47.7231 34.345 45.5196 32.4242 44.1574C30.8235 42.9956 29.8231 40.5516 29.8231 37.9475C29.8231 36.8657 29.9832 35.784 30.3033 34.8625C32.7843 33.5805 35.0652 31.1366 36.906 27.6109C38.7067 26.5292 40.4274 25.2071 42.028 23.6045C46.4298 19.1975 49.6311 13.1879 51.2317 6.25677C51.3117 5.85613 51.2317 5.49555 50.9916 5.2151ZM4.09285 7.17824H10.8956C11.0556 13.0276 12.016 18.5965 13.7367 23.284C9.37496 19.5981 5.93359 14.0292 4.09285 7.17824ZM30.9436 46.1206C31.904 46.8016 32.5842 47.8032 32.8644 48.885H19.6991C19.9792 47.8032 20.6195 46.8016 21.6199 46.1206C23.8608 44.518 25.2613 41.3529 25.2613 37.9475C25.2613 37.1863 25.1813 36.4651 25.0612 35.784C25.4614 35.8641 25.9016 35.8641 26.3017 35.8641C26.7019 35.8641 27.1421 35.8241 27.5422 35.784C27.4222 36.5052 27.3422 37.2263 27.3422 37.9475C27.3021 41.3529 28.7027 44.4779 30.9436 46.1206ZM26.2617 33.4202C19.2589 33.4202 13.3365 20.6798 13.3365 5.57568C13.3365 4.97472 13.3366 4.33369 13.3766 3.73273H39.1869C39.1869 4.33369 39.2269 4.97472 39.2269 5.57568C39.1869 20.6398 33.2645 33.4202 26.2617 33.4202ZM38.8267 23.284C40.5074 18.5965 41.5078 13.0276 41.6679 7.17824H48.4706C46.5899 14.0292 43.1485 19.5981 38.8267 23.284Z"
      fill={props.color || 'currentColor'}
      stroke={props.color || 'currentColor'}
      strokeWidth="2"/>
  </svg>
);
