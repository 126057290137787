import React, { createContext, useContext } from 'react';
import { useAuth, UserDetails } from './AuthContext';

type Props = {
  children: React.ReactNode;
};

const UserContext = createContext(null as UserDetails);

function UserProvider(props: Props) {
  const { data } = useAuth();
  return <UserContext.Provider value={data?.profile} {...props} />;
}

const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
