import React from 'react';
import styled from 'styled-components';
import { Header } from '../../components';

const Page = styled.section`
  color: #fff;
  text-align: center;

  @media (min-width: 640px) {
    padding: 90px;
  }

  ${Header} {
    padding-bottom: 2rem;
  }

  img {
    max-width: 80%;
  }
  
  p {
    font-size: 1.25rem;
    line-height: 1.5;
    
    :last-child {
      color: ${p => p.theme.colors.secondary};
    }
  }
`;

const team = [
  {
    name: 'Sam Kamani',
    place: 'New Zealand, India',
    role: 'CEO & CoFounder'
  },
  {
    name: 'Dmitry Iwai',
    place: 'New Zealand, Russia',
    role: 'CTO & CoFounder'
  },
  {
    name: 'Emanuel Ortega',
    place: 'Brazil, Argentina',
    role: 'Gamification Project Manager'
  },
  {
    name: 'Ezequiel J. Ortega',
    place: 'Argentina, USA',
    role: 'Latin America Community'
  },
  {
    name: 'Kyle Moreno',
    place: 'Philippines',
    role: 'UI/UX Designer'
  },
];

const Row = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
`

const TeamMate = styled.div`
  width: 240px;
  
  p {
    margin: 0;
  }
`

const Name = styled.p`
  font-weight: bold;
`
const Place = styled.p`
  font-weight: 200;
`

export const AboutPage = () => (
  <Page>
    <Header>About Us</Header>
    <Row>
      {team.map(({name, place, role}) => (
        <TeamMate key={name}>
          <img src={`/social/${name.split(' ')[0].toLowerCase()}.png`} alt={name} />
          <Name>{name}</Name>
          <Place>({place})</Place>
          <p>{role}</p>
        </TeamMate>
      ))}
    </Row>
    <p>
      We started Ensydr because we want to make finance fun and exciting. We want more people to get interested in the stock and crypto currency markets.
    </p>
    <p>
      Our core team has had multiple exits and over 50 years of combined software development and trading experience.
    </p>
    <p>
      Ensydr.com is for learning purposes only. Ensydr does not sell any stocks or cryptocurrency. Also we do not endorse or advise you to buy or sell any stocks, equities, crypto currencies or financial instruments of any kind.
    </p>
    <p>
      We encourage you to do your own research and learn more before making any financial decisions.
    </p>
  </Page>
);
