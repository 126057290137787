import React from 'react';
import { AuthProvider, UserProvider } from './AuthContext';

function ContextProvider({ children }: any) {
  return (
    <AuthProvider>
      <UserProvider>{children}</UserProvider>
    </AuthProvider>
  );
}

export default ContextProvider;
export * from './AuthContext';
