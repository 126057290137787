import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';

import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import { GlobalStyle, theme } from './globalStyle';
import client from './client';
import ContextProvider from './context';
import Routes, { ScrollToTop } from './routes';

const history = createBrowserHistory();
history.listen((location: any) => {
  ReactGA.set({ page: location.pathname})
  ReactGA.pageview(location.pathname)
})

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <ContextProvider>
          <GlobalStyle />
          <Router history={history}>
            <ScrollToTop />
            <Routes />
          </Router>
        </ContextProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
