import * as React from 'react';

export const CheckIcon = (props: any) => (
  <svg width="38" height="28" viewBox="0 0 38 28" {...props}>
    <g transform="translate(-6 -12)" fill="none" fillRule="evenodd">
      <path
        d="M9.806 25.61a2.083 2.083 0 10-2.946 2.946l10.417 10.417a2.083 2.083 0 002.946 0L43.14 16.056a2.083 2.083 0 10-2.946-2.946L18.75 34.554 9.806 25.61z"
        fill={props.color || 'currentColor'}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
