import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { InfoSubtitle } from '../../components';
import { panelStyles } from '../WinPage/WinPanel';

export const WinTitle = styled(InfoSubtitle)`
  font-weight: 700;
  color: ${(p) => p.theme.colors.secondary};
`;

const StyledWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'header header'
    'text text'
    'note note';
  overflow-y: scroll;
  max-height: 20rem;

  @media (min-width: 768px) {
    grid-template-areas:
      'header header'
      'text text'
      'note image';
  }

  @media (min-width: 800px) {
    grid-template-areas:
      'header header'
      'text image'
      'note image';
  }

  ${InfoSubtitle} {
    margin: 0;
  }

  p:not(${WinTitle}) {
    grid-area: text;
  }

  p:last-of-type:not(${InfoSubtitle}) {
    grid-area: note;
    color: #f5f5f5;
  }

  img {
    grid-area: image;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const Panel = styled.div`
  ${panelStyles};
  padding: 2rem 2.25rem 1rem;

  img:first-child {
    display: block;
  }

  ${WinTitle} {
    grid-area: header;
  }
`;

export const Decor = styled.div`
  position: absolute;
  top: 1.1rem;
  left: -3rem;
  border-bottom: 50px solid red;
  border-left: 40px solid transparent !important;
  border-right: 64px solid transparent !important;
  width: 188px;
  transform: rotate(-38.11deg);

  @media (min-width: 480px) {
    top: 0.825rem;
    left: -2.25rem;
  }
`;

export const DecorTitle = styled.p`
  position: absolute;
  top: 0;
  left: -0.5rem;
  transform: rotate(-38.11deg);
  color: #f5f5f5;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  width: min-content;
  text-align: center;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;

  ${Panel} {
    background-size: 0 100%;

    :hover {
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.7) 100%
      );
      background-repeat: no-repeat;
      background-size: 200% 100%;
      transition: background-size 0.5s, background-color 0.5s;
    }
  }
`;

type Props = {
  image: string;
  startDate: string;
  endDate: string;
  prize: string;
  prizeImg: string;
};

const DefaultWeeklyPanel = ({
  startDate,
  endDate,
  prize,
  prizeImg,
  image
}: Props) => (
  <Panel>
    <img src={image} alt="meme here" />
    <StyledWrapper>
      <WinTitle>Collect the most Ensydr points and win {prize}</WinTitle>
      <InfoSubtitle>
        Between {format(new Date(startDate), 'd MMMM')} -{' '}
        {format(new Date(endDate), 'd MMMM yyy')}
      </InfoSubtitle>
      <p>
        * Accurately predict prices for as many assets as possible to earn more
        Ensydr points.
      </p>
      <img src={prizeImg} alt={prize} />{' '}
    </StyledWrapper>
    <Decor />
    <DecorTitle>Monthly Tournament</DecorTitle>
  </Panel>
);

export const WeeklyPanel = styled(DefaultWeeklyPanel)``;
