import React, { FC, ChangeEvent } from 'react';
import styled from 'styled-components';

type Props = {
  id: string;
  onChange: (value: boolean) => void;
  value?: boolean;
  name?: string;
  disabled?: boolean;
}

const Label = styled.label`
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
  color: var(--color);

  &[aria-disabled="true"] {
    opacity: 0.7;
    color: var(--disabled);
    pointer-events: none;
  }
`
const Control = styled.span`  
  display: inline-grid;
  width: 1em;
  height: 1em;
  border-radius: 0.25em;
  border: 0.1em solid currentColor;

  svg {
    transition: transform 0.1s ease-in 25ms;
    transform: scale(0);
    transform-origin: bottom left;
  }
`
const Input = styled.span`
  display: grid;
  grid-template-areas: "checkbox";

  > * {
    grid-area: checkbox;
  }

  input {
    opacity: 0;
    width: 1em;
    height: 1em;

    &:focus + ${Control} {
      box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor;
    }

    &:checked + ${Control} svg {
      transform: scale(1);
    }

    &:disabled + ${Control} {
      color: var(--disabled);
    }
  }
`

export const Checkbox: FC<Props> = ({id, onChange, name, value, disabled, children}) => {
  const handleChange = ({target}: ChangeEvent<HTMLInputElement>) =>
    onChange(target.checked)

  return (
    <Label className="checkbox" htmlFor={id} aria-disabled={disabled}>
      <Input className="checkbox__input">
        <input id={id} type="checkbox" name={name} onChange={handleChange} checked={value}/>
        <Control className="checkbox__control">
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
            <path fill='none' stroke='currentColor' strokeWidth='3' d='M1.73 12.91l6.37 6.37L22.79 4.59'/>
          </svg>
        </Control>
      </Input>
      <span className="radio__label">{children}</span>
    </Label>
  )
}
