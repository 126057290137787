import * as React from 'react';

export const TrendUpIcon = (props: any) => (
  <svg width={50} height={30} viewBox="0 0 50 30" {...props}>
    <g transform="translate(0 -10)" fill="none" fillRule="evenodd">
      <path
        d="M48.28 10.448a2.073 2.073 0 011.11.579l-.099-.093c.03.026.058.052.085.08l.014.013a2.128 2.128 0 01.414.59 2.218 2.218 0 01.13.359l.017.075c.032.145.049.295.049.449V25a2.083 2.083 0 01-4.167 0v-7.471L29.598 33.765a2.084 2.084 0 01-2.82.116l-.126-.116-8.944-8.944L3.556 38.973A2.083 2.083 0 11.61 36.027l15.625-15.625a2.083 2.083 0 012.946 0l8.944 8.943 14.762-14.762h-7.47c-1.1 0-2.002-.853-2.078-1.934l-.006-.149c0-1.15.933-2.083 2.084-2.083h12.5c.053 0 .105.002.158.006h.014c.047.005.095.01.142.017z"
        fill={props.color || 'currentColor'}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
