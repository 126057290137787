import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Subtitle } from '../../components';

const ThanksText = styled(Subtitle)`
  font-size: 20px;
  color: ${(p) => p.theme.colors.primary};
`;

const ButtonBlock = styled.div`
  margin-top: 1rem;
  text-align: center;

  ${Button} {
    min-height: 39px;
  }
`;

const ConfirmEmail = gql`
  mutation confirmEmail($token: String!) {
    confirmEmail(token: $token) {
      email
    }
  }
`;

export const EmailConfirmed = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [setError] = useState(null);
  const [confirm, { loading }] = useMutation(ConfirmEmail);

  useEffect(() => {
    confirm({ variables: { token } }).catch(setError);
  }, [confirm, token]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loading ? (
        <ThanksText>Please, stand by...</ThanksText>
      ) : (
        <ThanksText>Thank you for confirming your email</ThanksText>
      )}
      {/* TODO: uncomment when email confirmation is fixed  {!!error && <ErrorBlock>Sorry, something went wrong...</ErrorBlock>} */}
      <ButtonBlock>
        <Button buttonStyle="secondary" onClick={() => navigate('/assets')}>
          Continue
        </Button>
      </ButtonBlock>
    </>
  );
};
