import styled from 'styled-components';

export const AssetsGrid = styled.div`
  display: grid;
  justify-content: center;

  @media (min-width: 640px) {
    grid-template-columns: 45% 45%;
    justify-content: space-between;
  }
  @media (min-width: 900px) {
    grid-template-columns: 30% 30% 30%;
  }
`;
