import styled from 'styled-components';
import { Button } from '..';

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  label {
    text-transform: uppercase;
    font-family: TradeGothicLT, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 0.5625rem;
    color: #cbcbcb;
  }

  input {
    background: #fdfdfd;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
    border: none;
    border-radius: 3px;
    max-width: 100%;
    padding: 1rem 0.5625rem;
    margin-bottom: 1.0625rem;
    font-family: Helvetica, sans-serif;
    font-size: 15px;
    line-height: 17px;
    color: #252525;
  }
  input + p {
    margin-top: -1rem;
  }

  input + div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.0625rem;
  }

  div > a {
    font-family: Helvetica, sans-serif;
    font-size: 1.25rem;
    line-height: 1.4375rem;
    color: #616161;
    text-decoration: underline;
  }

  // for react-select
  div > input {
    padding: 1rem 0.5625rem;
    margin-bottom: 0;
    box-shadow: none;
  }

  ${Button} {
    align-self: flex-end;
    min-height: 38px;
  }
`;
