import React, { createContext, useContext } from 'react';
import gql from 'graphql-tag';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import ReactGA from 'react-ga';

const ProfileFragment = gql`
  fragment User on User {
    name
    email
    country
    avatar
    coins
    power
    id
    allowMail
  }
`;

export const GetProfile = gql`
  query GetProfile {
    profile {
      ...User
    }
  }
  ${ProfileFragment}
`;

export const SignIn = gql`
  mutation SignIn($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      ...User
    }
  }
  ${ProfileFragment}
`;
export const SignUp = gql`
  mutation SignUp($details: UserInput!) {
    signup(details: $details) {
      ...User
    }
  }
  ${ProfileFragment}
`;
export const SignOut = gql`
  mutation SignOut {
    signout
  }
`;

export const Activity = gql`
  subscription Activity($id: ID!) {
    activity(id: $id) {
      power
      coins
    }
  }
`;

export type UserDetails = any;

export type ActivitySubscription = {
  activity: {
    coins: number;
    power: number;
  };
};

type AuthContextType = {
  data: UserDetails;
  signin: (email: string, password: string) => void;
  signup: (details: any) => void;
  signout: () => void;
  authLoading: boolean;
  authError?: ApolloError;
};

type Props = {
  children: React.ReactNode;
};

const AuthContext = createContext({} as AuthContextType);

function AuthProvider(props: Props) {
  const {
    data,
    loading: profileLoading,
    error: profileError,
    refetch,
    subscribeToMore
  } = useQuery(GetProfile);
  const [login, { loading: loginLoading, error: loginError }] = useMutation(
    SignIn
  );
  const [
    register,
    { loading: registerLoading, error: registerError }
  ] = useMutation(SignUp);
  const [signout] = useMutation(SignOut, {
    onCompleted: () => window.location.replace('/')
  });

  const authLoading = profileLoading || loginLoading || registerLoading;
  const authError = profileError || loginError || registerError;

  if (authLoading) return <div>{props.children}</div>;

  const signin = (email: string, password: string) =>
    login({ variables: { email, password } })
      .then(() => {
        ReactGA.event({
          category: 'User',
          action: 'Login'
        });

        return refetch()
      })
      .catch(console.log);

  const signup = (details: any) =>
    register({ variables: { details } })
      .then(() => {
        ReactGA.event({
          category: 'User',
          action: 'Create an Account'
        });

        return refetch();
      })
      .catch(console.log);

  if (data?.profile)
    subscribeToMore({
      document: Activity,
      variables: {
        id: data?.profile?.id
      },
      updateQuery: (prev, { subscriptionData: { data } }) => {
        if (!data) return prev;
        const { activity } = data;
        const profile = {
          ...prev.profile,
          power: activity.power,
          coins: activity.coins || prev.profile.coins
        };
        return { profile };
      }
    });

  return (
    <AuthContext.Provider
      // @ts-ignore
      value={{ data, signin, signup, signout, authLoading, authError }}
      {...props}
    />
  );
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
