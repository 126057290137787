import styled, { css } from 'styled-components';

type ButtonProps = {
  buttonStyle:
    | 'primary'
    | 'secondary'
    | 'secondaryGradient'
    | 'alert'
    | 'success'
    | 'medium'
    | 'light'
    | 'ghost';
  shape?: 'sharp' | 'round';
};

const borderRadius = (shape: ButtonProps['shape']) => {
  switch (shape) {
    case 'sharp':
      return 0;
    case 'round':
      return 25;
    default:
      return 5;
  }
};

export const Button = styled.button<ButtonProps>`
  background: ${p => p.theme.colors[p.buttonStyle]};
  color: ${p => (p.buttonStyle === 'ghost' ? '#616161' : '#fff')};
  border: ${p => (p.buttonStyle === 'ghost' ? 'solid 1px #616161' : 'none')};
  cursor: pointer;
  height: 2.5625rem;
  min-width: 9.625rem;
  padding: 6px 20px;
  font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: ${p => (p.buttonStyle === 'ghost' ? 'normal' : 'bold')};
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  border-radius: ${p => borderRadius(p.shape)}px;
  transition: 0.3s;

  @media (max-width: 640px) {
    font-size: 15px;
    line-height: 17px;
  }

  &&:hover {
    background: #929bfc66;
  }
  ${p =>
    p.disabled &&
    css`
      pointer-events: none;
      background: #929bfc66;
    `}
`;
