import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Header } from '../../components';
import { useUser } from '../../context';

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: 640px) {
    flex-direction: row;
    margin-bottom: 5rem;
  }
`;

const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 33.875rem;
  margin-top: 10px;
  justify-content: center;

  ${Button} {
    margin-top: 3rem;
    width: 16.9375rem;
    height: 3.4375rem;
    font-size: 1.25rem;
    line-height: 2.5rem;
    cursor: pointer;

    &:hover {
      background: linear-gradient(
        90deg,
        rgb(245, 145, 14) -47.09%,
        rgb(247, 146, 14) -11.48%,
        rgb(248, 186, 109) 165.93%,
        rgb(250, 187, 110) 165.93%
      );
    }
  }
`;

const Subtitle = styled.p`
  font-family: HelveticaNeue, Helvetica, sans-serif;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.375rem;
  color: #afb6d3;
  max-width: 426px;
`;

export const CTASection = () => {
  const user = useUser();

  return (
    <StyledSection>
      <CTAContainer>
        <Header>Crypto price predictions</Header>
        <Subtitle>
          What if you could see where the crypto markets are going to be
          tomorrow, in 2 days or next week?
        </Subtitle>
        {!user && (
          <Link
            to="/login"
            as={Button}
            buttonStyle="secondaryGradient"
            shape="round"
          >
            Access Predictions
          </Link>
        )}
      </CTAContainer>
      <img src="/shapes/crypto-algo.png" alt="algo" />
    </StyledSection>
  );
};
