import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const StyledBurger = styled.button<{ open: boolean }>`
  position: fixed;
  top: 1.5rem;
  left: ${p => (p.open ? '18rem' : '2rem')};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  
  @media (max-width: 480px) {
    ${p =>
      p.open &&
      css`
        left: auto;
        right: 2rem;
      `}
  }
  @media (min-width: 1280px) {
    display: none;
  }
  
  &:focus {
    outline: none;
  }

  span {
    width: 2rem;
    height: 0.25rem;
    background: #d1d5ff; //${({ theme, open }) =>
      open ? theme.colors.light : theme.colors.light};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }
    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

const Burger = ({ open, setOpen, ...props }: Props) => {
  return (
    <StyledBurger
      aria-label="Toggle menu"
      aria-expanded={open}
      open={open}
      onClick={() => setOpen(!open)}
      {...props}
    >
      <span />
      <span />
      <span />
    </StyledBurger>
  );
};

export default Burger;
