import styled from 'styled-components';
import { P } from '../Text';

export const CurrentData = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.75rem;

  @media (min-width: 640px) {
    flex-direction: column;
    margin-bottom: 0.75rem;
  }
`;

export const DataText = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #f7f7f7;
`;

export const CurrentPrice = ({ price }: { price: number }) => (
  <CurrentData>
    <div>
      <P>Current Price:</P>
      <DataText>${price} USD</DataText>
    </div>
  </CurrentData>
)
