import styled from 'styled-components';
import { PrizeCard } from '../../components';
import { prizes } from '../../fixtures/tournaments';

const StyledSection = styled.article`
  @media (max-width: 640px) {
    display: none;
  }

  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  margin-top: 2rem;

  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const PrizesSection = () => (
  <StyledSection>
    {prizes.map((prize, i) => (
      <PrizeCard
        key={i}
        onClick={() => {
          const section = document.querySelector(`#win-${i}`);
          section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }}
        {...prize}
      />
    ))}
  </StyledSection>
);
