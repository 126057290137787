import { ActivityBox } from '../ActivityPage/ActivityBox';
import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { AssetActivitySubscription, GetContestBids } from './queries';
import { ActivityEntry } from '../ActivityPage/ActivityEntry';
import { createActivityList } from '../ActivityPage/createActivityList';

export const PredictionsBox = ({ code }: { code: string }) => {
  const { data, loading, subscribeToMore } = useQuery(GetContestBids, {
    variables: { code },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: AssetActivitySubscription,
      variables: { code },
      updateQuery: (prev, { subscriptionData: { data } }) => {
        if (!data) return prev;
        const { assetActivity } = data;

        return Object.assign({}, prev, {
          contestActivity: [assetActivity, ...prev.contestActivity]
        });
      }
    });
    return () => unsubscribe();
  }, [code]); // eslint-disable-line react-hooks/exhaustive-deps

  const activity = data?.contestActivity ?? [];
  const activityList = createActivityList(activity);

  return (
    <ActivityBox
      activity={activityList}
      loading={loading}
      size="small"
      renderItem={({ item, index }) => (
        <ActivityEntry {...(item as any)} key={index} />
      )}
    />
  );
};
