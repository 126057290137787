import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Button, ErrorBlock, Form, LoadingDots } from '../../components';

type UpdatePasswordFormInput = {
  password: string;
  passwordConfirmation: string;
};

const ErrorHint = styled.p`
  font-size: 0.75rem;
  color: red;
`;

const UpdatePassword = gql`
  mutation UpdatePassword(
    $password: String!
    $passwordConfirmation: String!
    $token: String!
  ) {
    updatePassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      token: $token
    ) {
      name
    }
  }
`;

export const UpdatePasswordForm = () => {
  const {
    register,
    formState: {errors},
    getValues,
    handleSubmit
  } = useForm<UpdatePasswordFormInput>();
  const { token } = useParams();
  const [update, { data, loading, error }] = useMutation(UpdatePassword);
  useEffect(() => {
    if (data?.updatePassword?.name) {
      window.location.replace('/assets');
    }
  }, [data]);
  const onSubmit = async ({
    password,
    passwordConfirmation
  }: UpdatePasswordFormInput) => {
    try {
      await update({ variables: { password, passwordConfirmation, token } });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="password">New password</label>
        <input
          type="password"
          placeholder="Min 8 characters"
          {...register('password', { required: true, minLength: 8 })}
          autoComplete="new-password"
        />
        {errors.password && <ErrorHint>Password is required</ErrorHint>}
        <label htmlFor="confirm_password">Confirm Password</label>
        <input
          type="password"
          placeholder="Re-enter password"
          {...register('passwordConfirmation', {
            validate: (value) => value === getValues()['password']
          })}
          autoComplete="new-password"
        />
        {errors.passwordConfirmation && (
          <ErrorHint>Passwords don't match</ErrorHint>
        )}
        <div>
          <Button buttonStyle="secondary" type="submit" disabled={loading}>
            {loading ? <LoadingDots /> : 'Submit'}
          </Button>
        </div>
      </Form>
      {!!error && (
        <ErrorBlock>
          {error.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </ErrorBlock>
      )}
    </>
  );
};
