import React from 'react';
import styled from 'styled-components';
import { Subtitle } from '../../components';

const StyledSection = styled.section`
  position: relative;
  padding-top: 20px;

  @media (max-width: 640px) {
    padding-bottom: 60px;
  }
`;

const PrizesRow = styled.div`
  padding: 78px 42px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 640px) {
    padding: 30px 0 32px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: thin;
    scrollbar-color: #d1d5ff transparent;

    ::-webkit-scrollbar {
      height: 0.375rem;
      width: 2rem;
    }
    ::-webkit-scrollbar-thumb {
      background: #d1d5ff;
      border-radius: 5px;
    }
    img {
      height: 130px;
    }
  }
`;

const PrizeBox = styled.div`
  padding-right: 20px;
  margin: 0 auto;

  &:last-child {
    padding-right: 0;
  }

  & > p {
    text-align: center;
    font-family: HelveticaNeue, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #f7f7f7;
  }

  @media (max-width: 640px) {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Prize = ({ title, src }: { title: string; src: string }) => (
  <PrizeBox>
    <img src={src} alt={title} />
    <p>{title}</p>
  </PrizeBox>
);

export const PrizesSection = () => (
  <StyledSection>
    <Subtitle>Prizes you can win!</Subtitle>
    <PrizesRow>
      <Prize src="/prizes/bitcoin.png" title="Bitcoin" />
      <Prize src="/prizes/iphone.png" title="iPhone" />
      <Prize src="/prizes/headphones.png" title="Headphones" />
      <Prize src="/prizes/giftcard.png" title="Gift Cards" />
    </PrizesRow>
  </StyledSection>
);
