import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Button, LoadingDots, CoinIcon, PowerIcon } from '..';
import { useAuth, UserDetails } from '../../context';

const StyledNav = styled.nav`
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 1.5rem;
  width: 100%;

  a:first-child {
    margin-right: auto;
  }

  img#logo {
    @media (min-width: 768px) {
      width: 240px;
    }
  }
`;

const Control = css`
  height: 3.5rem;
  max-width: 9.1875rem;
  font-size: 1.25rem;
  line-height: 1.5rem;

  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
  background: inherit;
  position: relative;
  overflow:hidden;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 2000px ${p => p.theme.colors.light};
    filter: blur(10px);
    background: inherit;
  }
`;

const SignupButton = styled(Button)`
  ${Control}
`;

const ControlBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${p => p.theme.colors.light};
  padding: 6px 6px;
  font-family: HelveticaNeue, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  color: #fff;
  margin-right: 10px;
  min-width: 6.75rem;
  width: fit-content;

  & > img {
    height: 2.8125rem;
    width: 2.8125rem;
    object-fit: cover;
    border-radius: 50%;
    background-color: ${p => p.theme.colors.secondary};
    margin-right: 12px;
  }

  ${Control}
`;

const UserControls = styled.div`
  display: flex;
  justify-self: end;

  & > a {
    text-decoration: none;
  }
`;

const PowerInfo = ({ power }: { power: number }) => (
  <ControlBlock>
    <PowerIcon />
    {power}/10
  </ControlBlock>
);

const CoinsInfo = ({ coins }: { coins: number }) => (
  <ControlBlock>
    <CoinIcon />
    {coins}
  </ControlBlock>
);

const Info = styled.div<{ gotInfo: boolean }>`
  display: flex;

  @media (max-width: 640px) {
    display: ${p => (p.gotInfo ? 'flex' : 'none')};
  }
`;

const InfoBlock: FC<{ user: UserDetails }> = ({ user }) => (
  <Info gotInfo={!!user}>
    <PowerInfo power={user?.power ?? 0} />
    <CoinsInfo coins={user?.coins ?? 0} />
  </Info>
);

const ProfileInfo = ({ name, avatar }: { name: string; avatar: string }) => (
  <Link to="/profile">
    <ControlBlock>
      <img src={avatar || '/icons/user.svg'} alt="avatar" />
      {name.split(' ')[0]}
    </ControlBlock>
  </Link>
);

type NavBarProps = {
  user: UserDetails;
  showInfo: boolean;
  hideControls?: boolean;
};

export const NavBar = ({ user, showInfo, hideControls }: NavBarProps) => {
  const { authLoading } = useAuth();
  return (
    <StyledNav>
      <div>
        {!user && (
          <Link to="/">
            <img id="logo" src="/ensydr-logo.svg" alt="Ensydr" />
          </Link>
        )}
      </div>
      {!hideControls && (
        <UserControls>
          {showInfo && <InfoBlock user={user} />}
          {user ? (
            <ProfileInfo name={user.name} avatar={user.avatar} />
          ) : (
            <Link
              as={SignupButton}
              buttonStyle="light"
              shape="round"
              to="/login"
            >
              {authLoading ? <LoadingDots /> : 'Sign In'}
            </Link>
          )}
        </UserControls>
      )}
    </StyledNav>
  );
};
