import { Link } from 'react-router-dom';
import { format, formatDistanceToNowStrict, parseISO } from 'date-fns';
import styled from 'styled-components';

import { AssetPicture } from '../BidPage/AssetPicture';
import {
  CurrentPrice,
  InfoSubtitle,
  InfoWrapper,
  StyledPanel
} from '../../components';
import { WinTitle } from './WeeklyPanel';

const nextDate = '27th September';
const prize = '$25 Amazon Voucher';

const Panel = styled(StyledPanel)`
  flex-flow: column nowrap;
  max-height: 100%;

  @media (min-width: 870px) {
    flex-flow: column wrap;
    max-height: 410px;
  }

  ${InfoWrapper} {
    width: 100%;

    @media (min-width: 870px) {
      width: 40%;
    }
  }

  ${AssetPicture} {
    align-self: center;

    @media (min-width: 870px) {
      width: 60%;
    }
  }
`;

const FeatureTitle = styled(InfoSubtitle)`
  font-size: 30px;
  font-weight: 700;
  color: ${(p) => p.theme.colors.primary};
`;

const ClosingWarning = styled(FeatureTitle)`
  position: absolute;
  bottom: 0;
  text-align: center;
  background: rgb(81, 84, 111, 0.8);
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
`;

type Props = {
  contest: {
    id: string;
    code: string;
    startDate: string;
    endDate: string;
    closeDate: string;
    asset: {
      code: string;
      price: number;
    };
  };
  loading: boolean;
};

export const FeaturedPanel = ({ contest, loading }: Props) => {
  const { asset, code, endDate, closeDate } = contest;
  const { price } = asset;
  const now = new Date();
  const close = new Date(parseISO(closeDate));
  const isClosed = now > close;
  const codeDashed = code?.split('/').join('-');

  return (
    <Panel data-loading={loading}>
      <AssetPicture>
        <Link to={`/bid/${codeDashed}`}>
          <img src={`/assets/${codeDashed}-feat.png`} alt={code} />
        </Link>
        {!isClosed && (
          <ClosingWarning>
            Closing in {formatDistanceToNowStrict(parseISO(closeDate))}
          </ClosingWarning>
        )}
      </AssetPicture>
      <InfoWrapper>
        <FeatureTitle>Featured Asset</FeatureTitle>
      </InfoWrapper>
      {endDate && (
        <InfoWrapper>
          {isClosed ? (
            <InfoSubtitle>
              Prediction closed, new competition is starting on {nextDate}
            </InfoSubtitle>
          ) : (
            <Link to={`/bid/${codeDashed}`}>
              <InfoSubtitle>
                Predict price of {code} on {format(parseISO(endDate), 'PPp')}
              </InfoSubtitle>
            </Link>
          )}
        </InfoWrapper>
      )}
      <InfoWrapper>
        <WinTitle>Best Prediction wins {prize}!</WinTitle>
      </InfoWrapper>
      <CurrentPrice price={price} />
    </Panel>
  );
};
