import React from 'react';

import { Tab, TabList, TabPanel, Tabs } from './ActivityTabs';
import { PredictionsBox } from './PredictionsBox';
import { ChatBox } from './ChatBox';

export const ContestActivity = ({ code }: { code: string }) => {
  return (
    <Tabs defaultIndex={1}>
      <TabList>
        <Tab>Chat</Tab>
        <Tab>Predictions</Tab>
      </TabList>

      <TabPanel>
        <ChatBox code={code} />
      </TabPanel>
      <TabPanel>
        <PredictionsBox code={code} />
      </TabPanel>
    </Tabs>
  );
};
