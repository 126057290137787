import styled, { css } from 'styled-components';

const FlipBGShapes = css`
  background-image: url('/shapes/cta-shape.svg'), url('/shapes/accent-flip.svg'),
    url('/shapes/highlight-flip.svg'), url('/shapes/footer-flip.svg');
  background-position: top -70px left 0, top 910px left 1130px,
    top 840px left 1200px, bottom -100px left 0;
`;

const BGShapes = css`
  background-image: url('/shapes/cta-shape.svg'), url('/shapes/accent.svg'),
    url('/shapes/highlight.svg'), url('/shapes/footer.svg');
  background-position: top -70px left 800px, top 920px left 230px,
    top 850px left 300px, bottom 0 left 1100px;
`;

export const Main = styled.main<{
  flip: boolean;
  menuOpen: boolean;
  loggedIn: boolean;
}>`
  position: relative;
  max-width: 1024px;
  width: 100%;
  padding: 0 1.75rem;
  margin-bottom: 2rem;

  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 1280px) and (max-width: 1500px) {
    ${p =>
      p.loggedIn &&
      css`
        margin-left: 16rem;
      `};
  }

  @media (min-width: 480px) and (max-width: 1280px) {
    ${p =>
      p.menuOpen &&
      css`
        margin-left: calc(20vw + 16rem);
        opacity: 0.4;
      `}
  }

  &:before {
    content: ' ';
    position: absolute;
    width: 225%;
    height: 100vh;
    min-height: 1736px;
    top: -110px;
    left: -500px;
    z-index: -1;
    background-repeat: no-repeat;

    @media (min-width: 640px) {
      ${p => (p.flip ? FlipBGShapes : BGShapes)}
    }
  }
`;
