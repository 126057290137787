export const stocks: Array<{ code: string; name: string; type?: string }> = [
  {
    code: 'GOOG',
    name: 'Alphabet'
  },
  {
    code: 'AMZN',
    name: 'Amazon'
  },
  {
    code: 'AAPL',
    name: 'Apple, Inc.'
  },
  {
    code: 'FB',
    name: 'Facebook, Inc.'
  },
  {
    code: 'MSFT',
    name: 'Microsoft Corp.'
  },
  {
    code: 'NFLX',
    name: 'Netflix, Inc.'
  },
  {
    code: 'NKE',
    name: 'Nike, Inc.'
  },
  {
    code: 'TSLA',
    name: 'Tesla, Inc.'
  },
  {
    code: 'DASH',
    name: 'DoorDash, Inc.'
  },
  {
    code: 'ABNB',
    name: 'AirBnB, Inc.'
  },
  {
    code: 'GME',
    name: 'GameStop Corp.',
  },
  {
    code: 'AMC',
    name: 'AMC Entertainment'
  }
];

export const cryptos: Array<{ code: string; name: string; type?: string }> = [
  {
    code: 'BTC/USD',
    name: 'Bitcoin'
  },
  {
    code: 'ETH/USD',
    name: 'Ethereum'
  },
  {
    code: 'DOGE/USD',
    name: 'DogeCoin',
  },
  {
    code: 'BNB/USD',
    name: 'Binance Coin',
  },
  {
    code: 'ADA/USD',
    name: 'Cardano',
  },
  {
    code: 'MATIC/USD',
    name: 'Polygon',
  },
  {
    code: 'XRP/USD',
    name: 'XRP',
  },
  {
    code: 'UNIs/USD',
    name: 'Uniswap',
  },
  {
    code: 'pDOTn/USD',
    name: 'Polkadot',
  },
  {
    code: 'SOL/USD',
    name: 'Solana',
  }
];
