import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Button, ErrorBlock, Form } from '../../components';
import { useAuth, useUser } from '../../context/AuthContext';

type LoginFormData = {
  email: string;
  password: string;
};

export const LoginForm = () => {
  const { register, handleSubmit } = useForm<LoginFormData>();
  const navigate = useNavigate();
  const { signin, authLoading, authError } = useAuth();
  const user = useUser();
  useEffect(() => {
    if (user) navigate('/assets');
  }, [user, navigate]);
  const onSubmit = async ({ email, password }: LoginFormData) =>
    signin(email, password);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          aria-required="true"
          autoComplete="username"
          autoFocus
          {...register('email', { required: true })}
        />
        <label htmlFor="password">Password</label>
        <input
          type="password"
          aria-required="true"
          autoComplete="current-password"
          {...register('password', { required: true })}
        />
        <div>
          <Link to="/login/forgot">Forgot Password?</Link>
          <Button buttonStyle="secondary" type="submit" disabled={authLoading}>
            Login
          </Button>
        </div>
      </Form>
      {!!authError && (
        <ErrorBlock>
          {authError.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </ErrorBlock>
      )}
    </>
  );
};
