import { ActivityBox } from '../ActivityPage/ActivityBox';
import { MessageInput } from './MessageInput';
import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import ReactMarkdown from 'react-markdown';

import { useUser } from '../../context';
import { Entry, EntryTitle } from '../ActivityPage/ActivityEntry';
import { AssetChatSubscription, GetAssetChat } from './queries';

const ChatItem = ({
  item: { user, insertedAt, text },
  index
}: {
  item: any;
  index: number;
}) => (
  <Entry key={index}>
    <EntryTitle>
      <span>
        <img src={user.avatar || '/icons/user.svg'} alt="avatar" />
        <p>{user.name}</p>
      </span>
      <p>{formatDistanceToNowStrict(parseISO(insertedAt))} ago</p>
    </EntryTitle>
    <ReactMarkdown>{text}</ReactMarkdown>
  </Entry>
);

export const ChatBox = ({ code }: { code: string }) => {
  const user = useUser();
  const { data, loading, subscribeToMore } = useQuery(GetAssetChat, {
    variables: { code },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: AssetChatSubscription,
      variables: { code },
      updateQuery: (prev, { subscriptionData: { data } }) => {
        if (!data) return prev;
        const { messageSent } = data;

        return Object.assign({}, prev, {
          assetChat: [messageSent, ...prev.assetChat]
        });
      }
    });
    return () => unsubscribe();
  }, [code]); // eslint-disable-line react-hooks/exhaustive-deps

  const activity = data?.assetChat ?? [];

  return (
    <ActivityBox
      activity={activity}
      loading={loading}
      size="small"
      renderItem={ChatItem}
    >
      {user && <MessageInput code={code} />}
    </ActivityBox>
  );
};
