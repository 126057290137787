import React from 'react';
import styled from 'styled-components';
import { Header } from '../../components';

const Page = styled.section`
  text-align: left;
  color: #fff;

  ${Header} {
    padding-bottom: 2rem;
  }

  h2 {
    color: ${p => p.theme.colors.secondary};
  }
`;

export const TermsPage = () => (
  <Page>
    <Header>Terms of Service</Header>
    <h2>1. INTRODUCTION TO THESE TERMS AND CONDITIONS</h2>
    <p>
      These terms (the &#8220;Ensydr Terms&#8221;) are a legal agreement between
      you and Ensydr Limited (&#8220;Us&#8221; or &#8220;We&#8221;) and are the
      terms upon which We, at our discretion, permit you to access and use the
      Ensydr application (the &#8220;App&#8221;), our website located at
      Ensydr.com (the &#8220;Website&#8221;) and all associated information or
      content (including, without limitation, Third Party Content) accessible
      through, or in conjunction with, the App or Website
      (&#8220;Content&#8221;), including access through the RSS reader, subject,
      in the case of certain content, to payment of applicable charges.
    </p>
    <p>
      The Ensydr Terms also apply to any communications between you and Us by
      any means including software which We make available to you via the App,
      the Website or third party platforms (&#8220;Ensydr Software&#8221;).
      References in these terms to &#8220;Services&#8221; means the App, the
      Website, the Content, and/or the Ensydr Software.
    </p>
    <br />
    <h2>2. IMPORTANT NOTICE</h2>
    <p>
      By downloading and using the Services, you are acknowledging that you have
      read, understand and accept the Ensydr Terms. The Ensydr Terms are
      available in the English language only. If you do not agree to the Ensydr
      Terms, We will not license the Services to you, you must stop using the
      Services, and you must uninstall the Ensydr Software from your devices by
      following the method appropriate to your particular device. You have the
      right to withdraw from any transaction or subscription as described in
      section 13. Section 18 of the Ensydr Terms includes a limitation on our
      liability to you.
    </p>
    <br />
    <h2>3. INFORMATION ABOUT US</h2>
    <p>
      We are Ensydr Limited, a company registered in New Zealand (under
      registration number 7884736) whose principal place of business is Bizdojo,
      4 Williamson Avenue, Ponsonby, Auckland 1010.
    </p>
    <br />
    <h2>4. OPERATION SYSTEM REQUIREMENTS</h2>
    <p>
      To use the Services your device must meet certain operating system
      requirements, which are described in the FAQ.
    </p>
    <p>
      You should ensure your device meets these requirements before attempting
      to download, stream or use the Services.
    </p>
    <br />
    <h2>5. YOUR DEVICES</h2>
    <p>
      You must ensure you have all necessary rights to access the Services from,
      and/or download, or use the Ensydr Software to, the device(s) you use for
      these acts (&#8220;Device&#8221;). Charges may be applied by service
      providers for internet access on such Devices. You accept full
      responsibility for the use of the Services on or in relation to any
      Device, whether or not it is owned by you.
    </p>
    <br />
    <h2>6. YOUR PROMISES TO US</h2>
    <p>
      You promise Us that you are at least 16 years old. If you are 16 or older,
      but under the age of 18, you should review the Ensydr Terms with your
      parent or guardian to make sure that you and your parent or guardian have
      understood them to your satisfaction. You agree not to allow anyone under
      16 years old to access or use our Services from your Device.&#160; Please
      see section 8 Competitions for age exceptions.
    </p>
    <p>
      You also promise Us that any information and details provided by you to
      Us, including on registration for an account, are true, accurate and up to
      date in all respects and at all times. You can update or correct your
      personal details at any time from the &#8216;Edit Profile&#8217; screen in
      the &#8220;Profile&#8221; section of the App or Website.
    </p>
    <br />
    <h2>7. CHARGES AND PAYMENTS</h2>
    <p>
      You may download the App or access the Website free of charge. You may
      play the interactive games free of charge but you acknowledge that certain
      additional services and content such as, but not limited to, in-game
      features, premium content, may only be accessed by you in exchange for
      payment and which may require you to register further details about you.
    </p>
    <p>
      You acknowledge that by accepting to purchase Ensydr Services, you
      expressly consent to the supply of such services and content immediately
      and that by consenting to such supply, you acknowledge that you may lose
      your right to cancel such transaction within the 14 day cooling off period
      provided by law.
    </p>
    <p>
      Furthermore, where you purchase Services and Content, your payment will
      need to be authenticated using either Apple&#8217;s App Store or Google
      Play Store.&#160; You must abide by their rules and furthermore with their
      own terms of use, details of which can be found here:
      <a href="https://www.apple.com/legal/internet-services/itunes/us/terms.html">
        Apple Media Services Terms &amp; Conditions
      </a>
      &#160; or
      <a href="https://play.google.com/intl/en_ca/about/play-terms.html">
        Google Play Terms of Service
      </a>
    </p>
    <br />
    <h2>8. COMPETITIONS</h2>
    <p>
      We run multiple games and league tables, including invitation-only and
      private competitions in conjunction with third-party associates, each of
      which involves an element of skill, judgement or knowledge, consecutively
      or simultaneously. We also enable You to create your own private
      tournaments, and to join other participants&#8217; private tournaments at
      your own risk. Employees of Ensydr Limited or their family members or
      anyone else connected in any way with a Competition or helping to set up a
      Competition may, at Our discretion, enter a Competition. However, they are
      explicitly excluded from winning prizes.&#160;
    </p>
    <br />
    <h2>9. INCENTIVES</h2>
    <p>
      From time to time, We may offer a Refer a Friend scheme to Ensydr users
      (You) whereby You are invited to earn Referral Bonuses by inviting others
      to join Ensydr. Terms of the Ensydr Refer a Friend scheme are described
      separately and apply to You and your friends&#160; (together referred to
      as the &#8220;Participants&#8221;).
    </p>
    <p>
      We may suspend, terminate or change the terms and requirements of the
      Enysdr Refer a Friend scheme Terms at any time and for any reason.&#160;
    </p>
    <br />
    <h2>10. PERSONAL INFORMATION</h2>
    <p>
      Protecting your privacy is very important to Us.&#160; We like to keep in
      touch with you and We will send you information or advice about features
      and services that We provide.&#160; By using the App you agree that We can
      approach you in this way. We also understand that lots of emails can be
      annoying so We try to keep ours to a minimum.&#160; Nevertheless, if you
      feel you are getting too many you can always change your communication
      settings in the App, including selecting the unsubscribe link that exists
      in every communication We send.&#160; You can also change your email
      address through your Account settings in the App whenever you want to.
    </p>
    <p>
      Please note there are some communications that are integral to the
      Services We provide and cannot be opted out.&#160; This applies to, but
      not limited to, notifications advising you of changes in the terms of use
      and so on.
    </p>
    <p>
      For more detailed information please review our Privacy Policy in order to
      better understand our commitment to maintaining your privacy as well as
      our use and disclosure of your information. You agree to Us using your
      personal information as described in our{' '}
      <a href="https://ensydr.com/privacy/">privacy policy</a>.
    </p>
    <br />
    <h2>11. YOUR RIGHTS TO USE THE SERVICES</h2>
    <p>
      In exchange for you complying with the Ensydr Terms, We grant you, at our
      sole discretion, a revocable, non-transferable and non-exclusive licence
      to:
    </p>
    <p>
      access and use the App and/or Website; and download or stream a copy of
      the Ensydr Software and any Content onto your Device and to view, use and
      display the Ensydr Software and any Content on your Device(s).
    </p>
    <p>
      You acknowledge that your use of the Services grants you no rights in or
      to the Services or any of our intellectual property rights (including
      copyright, trademarks and patents) other than the right to use the
      Services in accordance with the Ensydr Terms. We do not sell the App, the
      Website, or Ensydr Software to you.
    </p>
    <p>
      You acknowledge and agree that internet transmissions are never completely
      private or secure. You understand that any messages or information you
      post, send or share using the App, the Website or the Ensydr Software may
      be read or intercepted by others.
    </p>
    <p>
      We may include links in our Services to third party applications, news
      services, including RSS feeds, or other service providers. We are not
      responsible for their privacy practices or the way they handle any
      personal information they may collect about you. You should ensure you
      read their privacy policies carefully before using these Services.
    </p>
    <br />
    <h2>12. RESTRICTIONS ON YOUR USE OF THE SERVICES</h2>
    <p>You agree:</p>
    <ol>
      <li>
        <p>
          not to copy the Services (or any part of any of them) except where
          such copying is incidental to your use of the Services in accordance
          with the Ensydr Terms;
        </p>
      </li>
      <li>
        <p>
          not to make alterations to, or modifications of, the whole or any part
          of the Services, or permit the Services or any part of any of them to
          be combined with, or become incorporated in, any other programs;
        </p>
      </li>
      <li>
        <p>
          not to disassemble, decompile, reverse-engineer or create derivative
          works based on the whole or any part of the Services or attempt to do
          any such thing except to the extent that such actions in respect of
          the Ensydr Software cannot be prohibited because they are essential
          for the purpose of achieving interoperability of the Ensydr Software
          with another software program, and provided that the information
          obtained by you during such activities:
        </p>
        <ul>
          <li>
            <p>
              is used only for the purpose of achieving interoperability of the
              Ensydr Software
            </p>
          </li>
          <li>
            <p>
              is not unnecessarily disclosed or communicated without our prior
              written consent
            </p>
          </li>
          <li>
            <p>
              is not used to create any software that is substantially similar
              to the Ensydr Software;
            </p>
          </li>
        </ul>
      </li>
      <li>
        <p>
          not to create alternative servers for use with our Services. You must
          not use our Services in conjunction with any servers other than the
          servers We provide;
        </p>
      </li>
      <li>
        <p>
          not to reverse engineer any data provided to you as part of the
          Services;
        </p>
      </li>
      <li>
        <p>
          not to rely on the accuracy of any data provided to you as part of the
          Services;
        </p>
      </li>
      <li>
        <p>
          not to use or create software which automatically interacts with our
          Services, such that the level of user interaction required is less
          than would be required without that software (except to the extent
          required for web accessibility purposes);
        </p>
      </li>
      <li>
        <p>
          not to do anything which may disrupt, damage, or impair our Services
          (or any part thereof), or prevent other users from using any part of
          our Services;
        </p>
      </li>
      <li>
        <p>
          not to intercept or modify the communications between our Services and
          our servers;
        </p>
      </li>
      <li>
        <p>not to deliberately exploit any bugs you find in our Services;</p>
      </li>
      <li>
        <p>not to use our Services for any illegal or unlawful purpose; and</p>
      </li>
      <li>
        <p>
          not to provide or otherwise make available the Services (or any data
          made available to you in the provision of the Services) in whole or in
          part (including any object and source code), in any form to any person
          without prior written consent from Us.
        </p>
      </li>
    </ol>

    <br />
    <h2>13. ENDING YOUR USE OF OUR SERVICES</h2>
    <p>
      You can simply choose to stop using the Services and remove the Ensydr
      Software from your Device(s) at any time. In addition, you may log out
      from the &#8220;Settings&#8221; screen of the App or Website.
    </p>
    <p>
      You acknowledge that your use of the Services is subject to our discretion
      and We may, at our sole discretion, withdraw your rights to use the
      Services on the provision of written notice with immediate effect. If our
      withdrawal of your rights to use the Services affects the cancellation of
      any subscription prior to the end of a month in respect of which you have
      already made a subscription payment, then you will be refunded a pro rata
      amount of such subscription payment corresponding to the period for which
      you have not received benefit of the subscription.
    </p>
    <br />
    <h2>14. ACCOUNT</h2>
    <p>
      You may need to create an account or accounts with Us to access or use the
      Ensydr Software or certain features of the App or our Website. Your
      account is personal to you and may not be transferred to or shared with
      others.
    </p>
    <p>
      You are responsible for keeping your account and login details secure and
      are responsible for all activities that are carried out under them. You
      should choose a strong, unique password, which you do not disclose to
      anyone else or use with any other service. You should also install
      anti-virus and firewall software on your Device and install security
      updates on your Device in a timely fashion. We will not be responsible for
      any losses suffered by you in circumstances where your account is used by
      someone else, unless this is caused by our negligence.
    </p>
    <br />
    <h2>15. ACCEPTABLE USE OF THE SERVICES</h2>
    <p>
      You acknowledge that the Services enable you to communicate with other
      users of our Services and post comments and briefings, messages,
      investment ideas, personal information, images, text and other material
      (&#8220;User Content&#8221;) which can be seen by others, for example, in
      Ensydr feeds, league tables, profiles, public or private chat groups or
      anywhere else in the App, Website or Ensydr Software.
    </p>
    <p>
      You agree that in using the App, Website and the Ensydr Software, you
      will:
    </p>
    <ol>
      <li>
        <p>
          not post or otherwise make available any User Content which is
          unlawful, defamatory, obscene, offensive, racist, illegal, incites
          hatred or criminal activity, has the effect of being harassing,
          threatening or abusive to any individual or group of individuals
          (including on the basis of religion, gender, sexual orientation, race,
          ethnicity, age, disability or otherwise) or which infringes any third
          party&#8217;s rights;
        </p>
      </li>
      <li>
        <p>
          not spread rumours in relation to any investments, markets or any
          other financial information;
        </p>
      </li>
      <li>
        <p>
          not create false impressions in relation to any investments, markets
          or any other financial information;
        </p>
      </li>
      <li>
        <p>
          not manipulate the financial markets in any way in your use of the
          Services;
        </p>
      </li>
      <li>
        <p>not discriminate against other users of the Services;</p>
      </li>
      <li>
        <p>
          not provide investment advice or hold yourself out as providing
          investment advice;
        </p>
      </li>
      <li>
        <p>not create spam messages;</p>
      </li>
      <li>
        <p>
          not distribute viruses or any other technologies that may harm Us or
          the interests of any other users of the Services, or otherwise
          interfere with or disrupt our systems;
        </p>
      </li>
      <li>
        <p>not do anything to bring Us or the Services into disrepute;</p>
      </li>
      <li>
        <p>
          not solicit or attempt to solicit personal information from any other
          user;
        </p>
      </li>
      <li>
        <p>
          not impersonate any other person, including falsely representing a
          company
        </p>
      </li>
      <li>
        <p>not advertise other products or services; and</p>
      </li>
      <li>
        <p>
          not use our messaging facilities to lie to or deceive other users
          (i.e. you must not &#8216;scam&#8217; other users) or to advertise or
          promote third party or your own products and services.
        </p>
      </li>
    </ol>
    <p>
      By posting or making available any User Content via our Services, or by
      sending any User Content to Us, you are promising Us that you have the
      right and all necessary approvals to use such User Content and that We can
      use it in any media without any payment or obligation to you or anyone
      else. You further promise Us that the availability or publishing of any
      such User Content as part of our Services will not cause Us to infringe
      the rights of any third party or to be in breach of any applicable law or
      regulation. You agree to compensate Us for any losses We suffer as a
      direct result of any breach by you of any of the promises made by you in
      section 18.
    </p>
    <p>
      You acknowledge that We, nor Administrators of Private League Chat Rooms,
      do not monitor or moderate User Content made available by users of the
      App, our Website or Ensydr Software. However, if We are made aware of any
      User Content which We reasonably consider does not comply with the Ensydr
      Terms, We may remove it and suspend the account of any User who continues
      to use the Services in an unacceptable way. Also, an Administrator of any
      Private League Chat Room can remove You from the group without recourse.
      Please note that any of the User Content posted via the functionality
      available on the App or Website is the opinion of the person posting or
      sending only and such postings do not constitute any form of
      recommendation, representation, endorsement or arrangement by Us. Although
      We have rules for the posting and uploading of User Content, our
      interactive features may be misused and sometimes User Content can still
      be posted that is misleading or deceptive. You should not therefore rely
      on any User Content being accurate or complete. You accept that if you do
      rely on any User Content which has been posted or uploaded, you do so at
      your own risk.
    </p>
    <p>
      If any User Content makes you feel threatened, or abused, or if you
      believe any User Content is offensive or otherwise breaches the Ensydr
      Terms, please use the report flag or contact Us via the &#8220;Report A
      Problem&#8221; option in Contact Support or by using the contact details
      set out at section 28 below.
    </p>
    <p>
      You may also flag any social content that you deem to be inappropriate.
    </p>
    <p>
      If you post or submit User Content, unless We indicate otherwise, you
      grant: (a) Us and our group companies a nonexclusive, royalty-free and
      fully sublicensable and transferable rights to use, reproduce, modify,
      adapt, publish, translate, create derivative works from, distribute, and
      display such User Content throughout the world, whether on a commercial or
      non-commercial basis, in any media; and (b) Us, our sub licensees and
      transferees the right to use the name that you submit in connection with
      such User Content, if We or our group companies should choose to do so.
    </p>
    <p>
      You agree that the rights you grant above are irrevocable during the
      entire period of protection of your intellectual property rights
      associated with such User Content. To the extent permitted by law, you
      agree to waive your right to be identified as the author of such User
      Content and your right to object to derogatory treatment of such User
      Content. You agree to perform all further acts necessary to perfect any of
      the above rights granted by you to Us including the execution of deeds and
      documents, at our request and cost.
    </p>
    <br />
    <h2>16. DISCLAIMERS</h2>
    <p>
      The information available through the provision of our Services (the
      &#8220;Information&#8221;) is provided to you for your personal
      information and educational entertainment purposes only. It is not
      intended as an offer, solicitation, invitation or inducement for the
      purchase, sale or holding of any financial instrument in any jurisdiction.
      The provision of our Services may contain information and opinion on
      investments that does not constitute independent investment research, nor
      non-independent research, and is therefore not subject to the regulatory
      protections afforded to research.
    </p>
    <p>
      The Information does not constitute any form of advice or recommendation
      by Us and is not intended to be relied upon by You in making (or
      refraining from making) any investment decisions. Appropriate independent
      advice should be obtained before making any investment decision.
    </p>
    <p>
      The Information may comprise views, opinions and recommendations of
      individuals from the general public who may have little or no training,
      education, experience or any other specialised knowledge of the markets,
      investing or other financial matters. Further, investment markets can
      change rapidly so that Information may be out-of-date. The views expressed
      should not be taken as statements of fact nor should reliance be placed on
      them when making investment decisions. We do not endorse any opinions or
      recommendations made in the course of the provision of Services to you,
      and We do not represent or guarantee that any of the information available
      is accurate, reliable, current, complete or appropriate for your needs.
    </p>
    <p>
      Any trading in financial instruments involves a risk of substantial
      losses. In connection with any such transaction, you should note that:
    </p>
    <ol>
      <li>
        <p>financial transactions are risky;</p>
      </li>
      <li>
        <p>
          the price of financial instruments, and the income derived from them,
          can go down as well as up, and investors may not get back the amount
          they invested; and
        </p>
      </li>
      <li>
        <p>
          past performance is not necessarily a guide to future performance.
        </p>
      </li>
    </ol>
    <p>
      In connection with your use of the Services and any User Content made
      available by you, you agree to comply with all applicable local, state,
      national or international law and regulation, including, but not limited
      to, the market abuse regime of the Financial Industry Regulatory Authority
      (FINRA). In particular, you agree to not use the App or the Website to:
    </p>
    <ol>
      <li>
        <p>
          upload, post, email, transmit or otherwise make available any
          information which:
        </p>
      </li>
      <li>
        <p>
          is inaccurate or misleading, or any information which would have the
          effect of distorting the market; or
        </p>
      </li>
      <li>
        <p>
          which you do not have a right to make available under any law or under
          contractual or fiduciary relationships (such as inside information,
          proprietary and confidential information learned or disclosed as part
          of employment relationships or under nondisclosure agreements); or
        </p>
      </li>
      <li>
        <p>
          impersonate any person or entity, or falsely state or otherwise
          misrepresent your affiliation with a person or entity.
        </p>
      </li>
    </ol>
    <br />
    <h2>17. CONTENT AND THIRD PARTY LINKS</h2>
    <p>
      You acknowledge that We are not responsible for the accuracy of the
      Content (including any Content for which you have paid or are obliged to
      pay for access in accordance with section 7). No representation or
      warranty, express or implied, is made to the accuracy or completeness of
      Information. We accept no liability for direct or consequential loss
      arising as a result of the accuracy, timeliness, completeness or
      usefulness of information available through the App or Website. Any
      arrangement made between you and any third party named on the App or
      Website is at your sole risk and responsibility.
    </p>
    <p>You further acknowledge that:</p>
    <ol>
      <li>
        <p>
          You are aware that there may be a conflict of interest between our use
          of Content and Us making available the Content to you in the course of
          providing you with the Services;&#160;
        </p>
      </li>
      <li>
        <p>
          You are not relying on the Content for the purpose of making any
          investment decisions; and
        </p>
      </li>
      <li>
        <p>
          You further acknowledge the additional disclaimers of Content
          providers
        </p>
      </li>
    </ol>
    <p>
      The Services may contain links to third party websites or Services that
      are not operated or controlled by Us, including, without limitation, Third
      Party Content. You acknowledge and agree that We are not responsible for
      and do not endorse their content or its accuracy. For the avoidance of
      doubt, this also includes use of the App&#8217;s RSS news reader
      capability whereby you may select to view additional third party content.
    </p>
    <p>
      You acknowledge that where Content is provided to you by a third party,
      such third party endeavours to ensure the accuracy and reliability of the
      information provided but does not guarantee its suitability, availability,
      service level, timelines, accuracy or reliability and accepts no liability
      (whether in tort or contract or otherwise), subject to its own terms and
      conditions (where applicable), for any loss or damage arising from any
      interruption, defect, error, delay, inaccuracies or omissions in such
      information.
    </p>
    <p>
      In addition, brand and company logos used by Ensydr are owned by the
      respective companies. The use of a company&#8217;s brand logo does not
      represent an endorsement of Ensydr by the company, nor that of the company
      by Ensydr, nor does it imply the existence of any contractual
      relationship.
    </p>
    <br />
    <h2>18. YOUR LEGAL RIGHTS AND THE LIMITS ON OUR LIABILITY TO YOU</h2>
    <p>
      Under no circumstances shall We have any liability to You for any loss or
      damage of any kind incurred as a result of You using the App, including
      participating in any Competition or Competitions as described in section 8
      above, or any reliance on any information provided by Us or or other users
      or participants through the App, including feeds and, or private chat
      channels. Your participation in any part of the App and your reliance on
      any information is solely at your own risk.&#160;
    </p>
    <p>
      If We breach the Ensydr Terms, We shall only be liable for losses which
      are a reasonably foreseeable consequence of such a breach up to a maximum
      of the greater of: (i) the amount you have paid to Us over the previous 6
      months; and (ii) 50 US Dollars. &#8220;Foreseeable&#8221; means that the
      losses could have been reasonably contemplated by you and Us at the time
      of entering into these Terms.
    </p>
    <p>
      We are not responsible for: (i) losses not caused by our breach; (ii)
      indirect losses which means loss to you which is a side effect of the main
      loss or damage and where you and We could not have reasonably anticipated
      that type of loss arising at the time of entering into the Ensydr Terms;
      (iv) any loss arising as a result of the accuracy, timeliness,
      completeness or usefulness of any Information; or (v) failure to provide
      the Services or to meet any of our obligations under the Ensydr Terms
      where such failure is due to events beyond our control (for example a
      network failure).
    </p>
    <p>
      You have certain rights under the law. These include that We will provide
      the Services with reasonable care and skill. Nothing in the Ensydr Terms
      (including in this section) is intended to or will affect your statutory
      rights. Save as expressly set out in this Agreement, all conditions,
      representations, warranties, undertakings or other terms whether express
      or implied, statutory or otherwise are excluded from this Agreement to the
      fullest extent permitted by law.
    </p>
    <br />
    <h2>19. REMEDY</h2>
    <p>
      If you breach the Ensydr Terms, then (without limiting any other remedy We
      may have), We may at our sole discretion, disable, partially disable,
      modify, or delete any or all of your accounts, block your IP address or
      Device from accessing our Services, adjust your Game data, or in any other
      lawful way partially or fully restrict your access to any of our Services.
    </p>
    <p>
      You also agree to compensate Us and keep Us compensated for any loss We
      suffer (including financial or reputational loss) howsoever arising, as a
      result of your breach of any of the Ensydr Terms.
    </p>
    <br />
    <h2>20. AVAILABILITY OF SERVICES</h2>
    <p>
      Whilst We use reasonable efforts to provide a reliable service and
      software, We do not and cannot guarantee that the App, our Website or our
      Ensydr Software will be available 100% of the time. All software may
      contain bugs, and all hardware can suffer failures. You also acknowledge
      that failures, defects or other issues which have a detrimental effect on
      the Services may occur due to events beyond our reasonable control, such
      as poor network communication issues.
    </p>
    <p>
      We want to continually improve our Services and so We reserve the right to
      alter the App, our Website and Ensydr Software at any time, including
      adding, removing or changing features (which may advantage or disadvantage
      you). We will always try to make changes in a way which We feel benefits
      our users. For example We may make changes to correct a mistake, or to
      replace an unpopular feature with one We feel more users will appreciate,
      or to make the system requirements better match the hardware of the
      majority of its users. We will try to give you notice of any material
      changes to the Services. If We change the Services to your detriment, you
      can terminate your agreement with Us (including any subscription you may
      have) at any time by logging out of your account, deleting the App from
      your device, or notifying us by email using info@Ensydr.com. Please see
      section 13 for more details on ending your use of the Services.
    </p>
    <br />
    <h2>21. CHANGES TO THE Ensydr TERMS</h2>
    <p>
      You agree that We may update or amend the Ensydr Terms to reflect updates
      to our Services and/or as a result of legal or regulatory changes. We will
      give you reasonable notice of any changes to the Ensydr Terms, and your
      continued use of the App, the Website or Ensydr Software will signify your
      agreement to those changes.
    </p>
    <br />
    <h2>22. RIGHTS OF THIRD PARTIES</h2>
    <p>
      The Ensydr Terms are not intended to give rights to anyone except you and
      Us.
    </p>
    <br />
    <h2>23. ASSIGNMENT</h2>
    <p>
      You acknowledge that We may transfer our rights under the Ensydr Terms
      (and any related claims) to any third party without having to obtain your
      prior consent.
    </p>
    <br />
    <h2>24. NO WAIVER OF LEGAL RIGHTS</h2>
    <p>
      If you breach the Ensydr Terms and We take no action against you, We will
      still be entitled to use our rights and remedies in any other situation
      where you breach the Ensydr Terms.
    </p>
    <br />
    <h2>25. ENTIRE AGREEMENT</h2>
    <p>
      The Ensydr Terms constitute all terms agreed upon between You and Us and
      supersede any prior agreements in relation to our Services. You represent
      that you have not accepted the Ensydr Terms in reliance on any oral or
      written representations made by Us that are not contained in the Ensydr
      Terms.
    </p>
    <p>
      You acknowledge that your right to access and use the Services is also
      subject to:
    </p>
    <ol>
      <li>
        <p>applicable law;</p>
      </li>
      <li>
        <p>
          any rules or policies applied by the relevant app store from which you
          access the App ;
        </p>
      </li>
      <li>
        <p>
          any additional terms and conditions of any third party which are
          applicable in respect of the purchase of Third Party Content which are
          brought to your attention prior to such purchase;
        </p>
      </li>
      <li>
        <p>the terms of your employment contract, where applicable;&#160;</p>
      </li>
      <li>
        <p>
          any rules or policies applied by any investment exchange, where
          applicable; or
        </p>
      </li>
      <li>
        <p>
          any rules or policies applied by any other content or news provider,
          where applicable.
        </p>
      </li>
    </ol>
    <br />
    <h2>26. VALIDITY</h2>
    <p>
      If any part of the Ensydr Terms is disallowed or found to be ineffective
      by any court or regulator, the other provisions shall continue to apply.
    </p>
    <br />
    <h2>27. DISPUTES</h2>
    <p>
      These conditions are governed by and construed in accordance with English
      law. We both agree to submit to the non-exclusive jurisdiction of the
      English courts, which means that you may bring a claim to enforce your
      consumer protection in the country in which you live.
    </p>
    <br />
    <h2>28. CONTACT DETAILS</h2>
    <p>
      If you have any questions, complaints or comments about Us or our services
      then please <a href="https://ensydr.com/about/contact">contact us</a> by
      email using <a href="mailto:support@ensydr.com">support@ensydr.com</a>, or
      fill out the contact Us form on ensydr.com&#160;
    </p>
  </Page>
);
