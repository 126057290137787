import styled from 'styled-components';

export const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (min-width: 640px) {
    flex-direction: row-reverse;
  }

  @media (max-width: 640px) {
    margin-top: 3rem;
  }
`;
export const InstructionsList = styled.ol`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  counter-reset: instruction-counter;
  list-style: none;
  font-family: Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.125rem;
  padding-left: 5rem;

  @media (max-width: 640px) {
    margin: 3rem 0;
  }
`;
export const InstructionItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #f7f7f7;
  margin: 0 0 7.5rem 0;
  counter-increment: instruction-counter;
  position: relative;
  padding-left: 2.5rem;

  &:before {
    content: counter(instruction-counter);
    font-size: 1.875rem;
    right: 100%;
    top: 0;
    margin-left: -20px;
    position: absolute;
    border-radius: 50%;
    padding-top: 0.625rem;
    height: 3.375rem;
    width: 3.375rem;
    background: ${p => p.theme.colors.secondary};
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 3rem;
  }
`;
export const Person = styled.img.attrs(() => ({
  src: '/shapes/person.svg',
  alt: 'Person'
}))`
  min-width: 150px;
  padding-bottom: 6rem;

  @media screen and (max-width: 640px) {
    padding-bottom: 0;
    max-height: 310px;
    min-width: 105px;
  }
`;
