import * as React from 'react';

export const PowerIcon = (props: any) => (
  <svg width={22} height={46} fill="none" viewBox="0 0 22 46" {...props}>
    <path
      fill="#F9A216"
      d="M1.381 5.442l6.953-4.327 4.446 2.38-2.724 16.782 7.705-6.843 2.927 1.533-8.29 29.927-.037-.022-.532-21.41-3.691 8.346-2.597-1.363-4.16-25.003z"
    />
    <path
      fill="#FFB92C"
      d="M1.417 5.421l5.637 3.037 5.53-3.751.196-1.212-4.446-2.38L1.417 5.42z"
    />
    <path
      fill="#F78C19"
      d="M13.572 22.187l3.637-2.391-4.193-2.23L9.97 20.27l3.603 1.917zm1.393 11.945l.609-7.413-3.714-1.973.194 7.839 2.911 1.547zM8.12 23.81l-4.04-2.149 1.461 8.781.364.196 2.233 1.17.64-1.453-.658-6.544zm-4.795-6.67l4.26 2.264-.593-4.842-4.492-2.39.825 4.967zm14.436-3.706l-2.206 1.957 4.372 2.327.761-2.75-2.927-1.534z"
    />
    <path
      fill="#FFD13E"
      d="M5.827 7.823l6.953-4.328-.263 18.16 8.171-6.688-8.238 29.895-.052.032 1.827-18.122-6.087 5.036L5.827 7.823z"
    />
    <path
      fill="#F78C19"
      d="M1.993 9.115l4.614 2.454-.304-3.491-4.918-2.616.608 3.653z"
    />
    <path
      fill="#FFE878"
      d="M8.334 1.115l-6.918 4.3 4.41 2.401 6.954-4.328s-5.95 3.39-6.903 3.386c-.954-.003-3.265-1.349-3.091-1.683.173-.333 5.548-4.076 5.548-4.076z"
    />
    <path
      fill="#FFF0B0"
      d="M6.261 7.553l-.434.27 2.31 23.985L6.262 7.553zm8.392 18.86l-.467.39-1.788 18.09.052-.031 2.203-18.449zm-2.113-4.776l-.008.457 8.156-7.127-8.149 6.67z"
    />
    <path
      fill="#fff"
      d="M6.773 17.648l5.962-11.166.045-2.987-3.755 2.336-2.871 5.377.619 6.44zm5.625 27.246l.052-.032 3.15-11.428-2.523 4.723-.679 6.737zm-4.26-13.086l6.087-5.036-.105 1.07 6.242-11.688.326-1.187-8.171 6.687.13-9.044L7.26 22.695l.878 9.113z"
      opacity={0.3}
    />
    <path
      fill="url(#prefix__a)"
      d="M1.143 5.742l6.952-4.328 4.447 2.38-.21 14.55 5.191-4.61 2.927 1.533-8.29 29.926-.037-.022-.404-16.223-3.82 3.16-2.596-1.363-4.16-25.003z"
      opacity={0.2}
    />
    <defs>
      <linearGradient
        id="prefix__a"
        x1={-1.944}
        x2={16.174}
        y1={3.534}
        y2={53.357}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} />
      </linearGradient>
    </defs>
  </svg>
);
