import styled from 'styled-components';
import { Bookmark, ESymbol } from '../../components';

export const TopRanks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 50px;
`;
export const Name = styled.p`
  font-size: 10px;
  line-height: 12px;
  font-weight: bold;
  text-align: center;
  color: #d1d5ff;
  margin: 0;

  @media (min-width: 640px) {
    font-size: 20px;
    line-height: 28px;
  }
`;
export const Coins = styled.p`
  font-weight: bold;
  margin: 0;
  font-size: 10px;
  line-height: 12px;

  @media (min-width: 640px) {
    font-size: 20px;
    line-height: 24px;
  }

  ${ESymbol} {
    margin-right: 2px;

    @media (max-width: 640px) {
      transform: scale(0.5) translate(8px, 8px);
    }
  }
`;
export const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background: rgba(86, 94, 174, 0.5);
  border-radius: 25px;
  padding: 16px;
  min-width: 88px;
  width: 30%;
  height: 120px;

  @media (min-width: 640px) {
    min-width: 210px;
    height: 210px;
  }

  img {
    margin: 0 auto;
    height: 4.2rem;
    width: 4.2rem;
    object-fit: cover;
    border-radius: 50%;
    background-color: ${p => p.theme.colors.secondary};
    
    @media (min-width: 640px) {
      height: 6.875rem;
      width: 6.875rem;
    }
  }

  ${Bookmark} {
    position: absolute;
    top: -4px;
    right: 10px;

    @media (max-width: 640px) {
      top: -15px;
      right: 5px;
      transform: scale(0.5);
    }
  }

  &:nth-child(1) {
    order: 1;
    height: 170px;
    margin-right: 8px;

    @media (min-width: 640px) {
      height: 280px;
    }

    ${Name} {
      font-size: 15px;
      line-height: 13px;

      @media (min-width: 640px) {
        font-size: 30px;
        line-height: 28px;
      }
    }
    
    img {
      height: 6.5rem;
      width: 6.5rem;

      @media (min-width: 640px) {
        height: 8.75rem;
        width: 8.75rem;
      }
    }
  }

  &:nth-child(2) {
    order: 0;
    margin-right: 8px;
  }
  &:nth-child(3) {
    order: 2;
  }
`;
