import { format, parseISO } from 'date-fns';
import styled from 'styled-components';

import { InfoSubtitle } from './BidPanel';
import { Row, Table } from '../../pages/LeaderboardPage/LeaderTable';
import { useUser } from '../../context';

type Props = {
  contest: any;
}

const StyledTable = styled(Table)`
  ${Row} {
    height: 3rem;
    margin-bottom: 0.5rem;

    td {
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding-left: 2rem;
  
      &:first-child {
        border-radius: 10px 0 0 10px;
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
  }
`

export const CurrentBids = ({ contest }: Props) => {
  const user = useUser();
  const { endDate, bids } = contest;

  return (<>
    <InfoSubtitle>
      Predictions for {format(parseISO(endDate), 'PPp')}
    </InfoSubtitle>
    <StyledTable>
      <tbody>
      {bids.map(({amount, user: u}: any) => (
        <Row highlight={u.id === user.id} key={u.id}>
          <td>{u.name}</td>
          <td>${amount}</td>
        </Row>
      ))}
      </tbody>
    </StyledTable>
  </>)
}
