import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

export const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  @font-face {
    font-family: HelveticaNeue;
    src: url('/fonts/HelveticaNeue.ttf');
    font-weight: normal;
  }
  @font-face {
    font-family: HelveticaNeue;
    src: url('/fonts/HelveticaNeueBold.ttf');
    font-weight: bold;
  }
  @font-face {
    font-family: TradeGothicLT;
    src: url("/fonts/TradeGothicLT.ttf");
  }
  @media (max-width: 480px) {
    html {
      font-size: 12px;
    }
  }
  
  *, *:after, *:before {
    box-sizing: border-box;
    transition: .5s ease-in-out;
  }
  html {
    height: 100%;
    width: 100%;
  }
  
  body {
    font-family: HelveticaNeue, Helvetica, sans-serif;
    font-style: normal;
    color: #333;
    background-color: #090D33;
    min-height: 100%;
    min-width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    overflow: hidden;
  }
`;

const darkBlueTheme = {
  darkest: '#090d33',
  dark: '#1e2777',
  medium: '#565eae',
  // light: '#d1d5ff'
  light: '#d1d5ff66'
};

// const lightGrayTheme = {
//   darkest: '#252525',
//   dark: '#616161',
//   medium: '#cbcbcb',
//   light: '#f7f7f7'
// };

const colors = {
  primary: '#ef0e74',
  secondary: '#f69b26',
  secondaryGradient:
    'linear-gradient(90deg, #F69B26 -47.09%, #F69B26 -11.48%, #FAD09D 165.93%, #FAD09D 165.93%);',
  alert: '#e24c4c',
  success: '#55d8a5',
  ghost: '#F7F7F7',
  ...darkBlueTheme
};

export const theme = {
  colors
};
