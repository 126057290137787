import React from 'react';
import styled from 'styled-components';
import { VictoryArea } from 'victory';

const GraphContainer = styled.div`
  position: absolute;
  bottom: -3px;
  left: 0;
  opacity: 0.75;
  width: 100%;
`;

const Graph = ({ data }: { data: Array<{ price: number; date?: string }> }) => (
  <GraphContainer>
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient
          id="golden"
          x1="0"
          y1="0"
          x2="0"
          y2="257"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F69B26" />
          <stop offset="0.236763" stopColor="#F6A235" />
          <stop offset="1" stopColor="#FAD09D" />
          <stop offset="1" stopColor="#FAD09D" />
        </linearGradient>
      </defs>
    </svg>
    <VictoryArea
      scale={'time'}
      style={{ data: { fill: 'url(#golden)' } }}
      data={data}
      y="price"
      x="date"
      padding={{ top: 100, right: 0, bottom: 0, left: 0 }}
    />
  </GraphContainer>
);

export default Graph;
