import React from 'react';
import styled from 'styled-components';
import { Header, Subtitle } from '../../components';
import { WinPanel } from './WinPanel';
import { PrizesSection } from './PrizesSection';
import { tournaments } from '../../fixtures/tournaments';

const Page = styled.section`
  color: #fff;
  text-align: center;

  article {
    text-align: left;

    :last-of-type {
      margin-bottom: 2rem;
    }
  }
`;

export const WinPage = () => (
  <Page>
    <Header>Win</Header>
    <Subtitle>New prizes and rewards added every month!</Subtitle>
    <PrizesSection />
    <Header>How to win</Header>
    {tournaments.map((tournament, i) => (
      <WinPanel {...tournament} key={i} id={`win-${i}`} />
    ))}
  </Page>
);
