import React from 'react';
import styled from 'styled-components';
import { Header, H2, P } from '../../components';

const Page = styled.section`
  color: #fff;

  @media (min-width: 640px) {
    padding: 90px;
  }

  ${Header} {
    text-align: center;
    padding-bottom: 2rem;
  }

  article {
    margin-bottom: 2rem;
  }
`;

const faqEntries = [
  {
    q: 'What is Ensydr?',
    a:
      'Ensydr is a platform where you can speculate on the prices of stocks, fx and crypto to win real world prizes. Unlike the real stock market there is no risk. Ensydr provides an easy to learn environment for novice and amateur investors.'
  },
  {
    q: 'How do I sign up to Ensydr?',
    a:
      'When you want to predict a price of an asset you will be prompted to Login or signup. Alternatively click on the top right hand side button to signup or login.'
  },
  {
    q: 'How much does Ensydr cost?',
    a: 'It is free to join and play.'
  },
  {
    q: 'How do I win prizes?',
    a:
      'When you make good predictions, you get score points. The more points you win, the more prizes you get.'
  },
  {
    q: 'Is there any criteria to join Ensydr?',
    a:
      'You can join Ensydr from anywhere, however currently we ship prizes to only the places where Amazon ships'
  },
  {
    q: 'How will you send me the prize?',
    a: 'We use Amazon to ship physical prizes'
  },
  {
    q: 'What do you mean by Asset?',
    a:
      'An individual stock or Crypto Currency like BitCoin, Etherium or Fiat currency like US Dollar'
  },
  {
    q: 'What is EnsydrCoin?',
    a:
      'It is the currency for the Ensydr platform that you win for participating and making accurate predictions'
  },
  {
    q: 'What is EnsydrPower?',
    a:
      'You need EnsydrPower to make predictions. Everyday you get 10 powerunits. You use 2 EnsydrPower units per prediction'
  },
  {
    q: 'Why is my EnsydrPower zero?',
    a:
      'Everytime you predict something you use 2 Ensdyr Power units. If you have made 5 predictions today your balance will be 0. Your Power resets daily'
  },
  {
    q: 'How can I win more EnsydrCoin?',
    a: 'Make better predictions, and invite friends or challenge friends'
  },
  {
    q: 'What do I do with EnsydrCoins?',
    a:
      'Use Coins to take part in Raffles, go on leaderboards and more features coming soon...'
  }
];

export const FAQPage = () => (
  <Page>
    <Header>FAQ</Header>
    {faqEntries.map(({ q, a }) => (
      <article key={q}>
        <H2>{q}</H2>
        <P>{a}</P>
      </article>
    ))}
  </Page>
);
