import styled from 'styled-components';

export const Header = styled.header`
  font-family: HelveticaNeue, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 3.75rem;
  line-height: 3.75rem;
  margin-bottom: 1rem;
  color: #fff;
`;
