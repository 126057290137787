import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
// import { Link } from 'react-router-dom';

import { H2, Header, InfoSubtitle } from '../../components';
import { cryptos, stocks } from '../../fixtures/assets';

import { AssetCard } from './AssetsCard';
import { AssetsGrid } from './AssetsGrid';
import { AssetsSection } from './AssetsSection';
import { StyledLink, WeeklyPanel } from './WeeklyPanel';
import { FeaturedPanel } from './FeaturedPanel';
import { weeklyContest } from '../../fixtures/tournaments';
// import { GroupBanner } from './CommunityContestPage';

const ListFeatured = gql`
  query listFeatured {
    listFeatured {
      id
      code
      startDate
      endDate
      closeDate
      asset {
        code
        price
      }
    }
  }
`;

export const AssetsPage = () => {
  const { data, error, loading } = useQuery(ListFeatured, {
    fetchPolicy: 'cache-and-network'
  });
  const [contest] = data?.listFeatured ?? [];

  return (
    <AssetsSection>
      <Header>Play. Predict. Win.</Header>
      <InfoSubtitle>
        Predict future prices for your favorite tickers and win!
      </InfoSubtitle>

      <StyledLink to={`/weekly`}>
        <WeeklyPanel {...weeklyContest} />
      </StyledLink>

      {!error && contest && (
        <FeaturedPanel contest={contest} loading={loading} />
      )}

      {/*<Link to="/community">*/}
      {/*  <GroupBanner />*/}
      {/*</Link>*/}

      <H2>Stocks</H2>
      <AssetsGrid>
        {stocks.map(
          (asset) =>
            asset.type !== 'closed' && (
              <AssetCard asset={asset} key={asset.code} />
            )
        )}
      </AssetsGrid>

      <H2>Crypto</H2>
      <AssetsGrid>
        {cryptos.map(
          (asset) =>
            asset.type !== 'closed' && (
              <AssetCard asset={asset} key={asset.code} />
            )
        )}
      </AssetsGrid>
    </AssetsSection>
  );
};
