import styled from 'styled-components';
import {
  Tab as BaseTab,
  TabList as BaseTabList,
  TabPanel as BaseTabPanel,
  Tabs as BaseTabs
} from 'react-tabs';
import { ActivityContainer } from '../ActivityPage/ActivityBox';

export const Tab = styled(BaseTab)`
  position: relative;
  list-style: none;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.75rem;
  padding: 0.5rem 1.25rem;
  backdrop-filter: blur(2px);
  color: #c4c4c4;
  z-index: 0;
  cursor: pointer;
  transition: 0s;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border: 1px solid rgba(86, 94, 174, 0.7);
    border-bottom: none;
    border-radius: 0.4em 0.4em 0 0;
    background: rgba(86, 94, 174, 0.7);
    transform: scale(1.13, 1);
  }

  &[aria-selected='true'] {
    color: #f7f7f7;
    cursor: initial;
    z-index: 3;
  }

  &:hover {
    color: #f7f7f7;
  }

  &:nth-child(2)::before {
    background: rgba(94, 98, 138, 0.7);
  }
`;

export const Tabs = styled(BaseTabs)`
  -webkit-tap-highlight-color: transparent;
  width: 100%;
`;

export const TabList = styled(BaseTabList)`
  display: flex;
  margin: 0;
  padding-left: 0.4rem;
`;

export const TabPanel = styled(BaseTabPanel)`
  ${ActivityContainer} {
    border-top-left-radius: 0;
    background: rgba(86, 94, 174, 0.7);
  }

  &:nth-child(3) > ${ActivityContainer} {
    border-top-left-radius: 5px;
    background: rgba(94, 98, 138, 0.7);
  }
`;
