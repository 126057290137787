import { FC, MouseEvent } from 'react';
import styled, { css } from 'styled-components';
import { Header } from '../Header/Header';
import { Button } from '../Button/Button';

type Type = 'success' | 'alert' | 'info';
type ModalProps = {
  title?: string;
  type: Type;
  hide: false | (() => void);
  isShow: boolean;
};

const ModalDialog = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 800px;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #f7f7f7;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  border-radius: 25px;
  text-align: center;
  transform: translateY(-50%);
  transition: transform 0.3s ease-out;

  @media (min-width: 640px) {
    height: auto;
    margin: 19rem auto;
    padding: 3rem 4rem;
  }

  p {
    font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    color: #616161;
    padding: 1rem;
    margin-top: 0;
    margin-bottom: 1.25rem;

    @media (min-width: 640px) {
      padding-right: 125px;
      padding-left: 125px;
    }
  }
`;

const ModalContainer = styled.div<{ isShow?: boolean }>`
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 20;
  transition: 0.3s ease-in;
  ${p =>
    p.isShow &&
    css`
      visibility: visible;
      opacity: 1;

      ${ModalDialog} {
        transform: translateY(0);
      }
    `}
`;

export const ModalTitle = styled(Header)<{ type: Type }>`
  font-size: 5rem;
  line-height: 6rem;
  color: ${p => p.theme.colors[p.type]};
`

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-around;

  ${Button} {
    font-size: 30px;
    line-height: 37px;
    height: 75px;
    width: 285px;
  }
  @media (max-width: 640px) {
    ${Button}:first-child {
      width: 50%;
      border-bottom-left-radius: 25px;
    }
    ${Button}:last-child {
      width: 50%;
      border-bottom-right-radius: 25px;
    }
  }
`;

export const Modal: FC<ModalProps> = ({
  type,
  title,
  hide,
  isShow,
  children
}) => {
  const handleClickBackdrop = (e: MouseEvent): void => {
    const { id } = e.target as HTMLDivElement;

    if (id === 'modal' || id === 'modal-dialog') hide && hide();
  };

  return (
    <ModalContainer
      id="modal"
      tabIndex={-1}
      role="dialog"
      onClick={handleClickBackdrop}
      aria-labelledby="modal-label"
      isShow={isShow}
    >
      <ModalDialog aria-hidden="true" aria-modal="true">
        {title && <ModalTitle type={type}>{title}</ModalTitle>}
        {children}
      </ModalDialog>
    </ModalContainer>
  );
};

export default Modal;
