import React from 'react';
import styled from 'styled-components';
import { Header, H2, P } from '../../components';

const Page = styled.section`
  color: #fff;
  text-align: left;

  @media (min-width: 640px) {
    padding: 90px;
  }

  ${Header} {
    text-align: center;
    padding-bottom: 2rem;
  }

  a {
    text-decoration: none;
    color: #cbcbcb;

    :hover {
      color: #f60070;
    }
  }

  article {
    text-align: left;
  }
`;

export const RoadmapPage = () => (
  <Page>
    <Header>Roadmap</Header>
    <article>
      <H2>What are we building next on Ensydr?</H2>
      <P>
        <ul>
          <li>Website redesign</li>
          <li>Weekly tournaments - live results</li>
          <li>Internationalization</li>
        </ul>
      </P>
    </article>
    <H2>
      <a href="https://ensydr.medium.com/may-june-2021-update-982cc7864028">
        May-June 2021 Updates
      </a>
    </H2>
    <H2>
      <a href="https://ensydr.medium.com/april-2021-update-aac7b516e891">
        April 2021 Updates
      </a>
    </H2>
    <H2>
      <a href="https://ensydr.medium.com/march-2021-update-826c0e0c984c">
        March 2021 Updates
      </a>
    </H2>
    <H2>
      <a href="https://ensydr.medium.com/jan-feb-2021-update-27fe0791c0cc">
        January-February 2021 Updates
      </a>
    </H2>{' '}
    <H2>
      <a href="https://ensydr.medium.com/dec-2020-update-be225d949cc8">
        December 2020 Updates
      </a>
    </H2>
    <H2>
      <a href="https://ensydr.medium.com/nov-2020-update-c73d927456f6">
        November 2020 Updates
      </a>
    </H2>
    <H2>
      <a href="https://ensydr.medium.com/oct-2020-update-ebaa8b0fb84">
        October 2020 Updates
      </a>
    </H2>
    <H2>
      <a href="https://ensydr.medium.com/sep-2020-update-4a5b9b184c65">
        September 2020 Updates
      </a>
    </H2>
    <H2>
      <a href="https://ensydr.medium.com/aug-2020-update-351622e8dde8">
        August 2020 Updates
      </a>
    </H2>
  </Page>
);
