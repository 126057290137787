import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { format } from 'date-fns';

import { Header, InfoSubtitle } from '../../components';

import { AssetsSection } from './AssetsSection';
import { WeeklyPanel } from './WeeklyPanel';
import { LeaderTable } from '../LeaderboardPage/LeaderTable';
import { useUser } from '../../context';
import { weeklyContest } from '../../fixtures/tournaments';

const GetWeeklyScore = gql`
  query getWeeklyScore($from: Datetime!, $to: Datetime!) {
    getWeeklyScore(from: $from, to: $to) {
      id
      coins
      rank
      name
      country
    }
  }
`;

export const WeeklyContestPage = () => {
  const user = useUser();
  const { data } = useQuery(GetWeeklyScore, {
    variables: { from: weeklyContest.startDate, to: weeklyContest.endDate },
    fetchPolicy: 'cache-and-network'
  });
  const leaders = data?.getWeeklyScore ?? [];

  return (
    <AssetsSection>
      <Header>Weekly tournament</Header>
      <InfoSubtitle>
        {format(new Date(weeklyContest.startDate), 'd MMMM yyy')} -{' '}
        {format(new Date(weeklyContest.endDate), 'd MMMM yyy')}
      </InfoSubtitle>
      <WeeklyPanel {...weeklyContest}/>
      <LeaderTable users={leaders} highlight={user?.id}/>
    </AssetsSection>
  );
};
