import styled from 'styled-components';
import { Header } from '../../components';

export const AssetsSection = styled.section`
  text-align: center;
  margin: 6.25rem 0;

  ${Header} {
    margin-bottom: 0;
  }

  @media (min-width: 640px) {
    padding: 0 5.125rem;
  }
`;
