import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select, { StylesConfig } from 'react-select';
import { useNavigate } from 'react-router-dom';
import countryList from 'react-select-country-list';
import styled from 'styled-components';
import { Button, ErrorBlock, Form } from '../../components';
import { useAuth, useUser } from '../../context';

interface SignupData {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
}

type SignupFormData = SignupData & {
  country: { value: string; label: string };
};

const ErrorHint = styled.p`
  font-size: 0.75rem;
  color: red;
`;

type IsMulti = false;
const stylesConfig: StylesConfig<{ value: string; label: string }, IsMulti> = {
  control: (provided) => ({
    ...provided,
    marginBottom: '5%',
    width: '100%',
    border: 'none'
  }),
  input: (provided) => ({
    ...provided,
    padding: '10px 0'
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'Helvetica,sans-serif',
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '17px'
  }),
  singleValue: (provided) => ({
    fontSize: 15,
    lineHeight: '17px'
  })
};

export const SignupForm = () => {
  const [countries, setCountries] = useState([]);
  const {
    register,
    formState: {errors},
    handleSubmit,
    getValues,
    control
  } = useForm<SignupFormData>();
  const { signup, authLoading, authError } = useAuth();
  const navigate = useNavigate();
  const user = useUser();
  useEffect(() => {
    if (user) navigate('/assets');
  }, [user, navigate]);
  useEffect(() => {
    const options = countryList().getData();
    setCountries(options);
  }, []);

  const onSubmit = async (data: SignupFormData) => {
    const country = data.country.value;
    const peepNumber = Math.ceil(Math.random() * 105);
    const avatar = `/peeps/peep-${peepNumber}.svg`;
    signup({ ...data, country, avatar });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          {...register('name', { required: true })}
        />
        {errors.name && <ErrorHint>Name is required</ErrorHint>}
        <label htmlFor="email">Email</label>
        <input
          type="email"
          {...register('email', { required: true })}
          autoComplete="username"
        />
        {errors.email && <ErrorHint>Email is required</ErrorHint>}
        <label htmlFor="password">Password</label>
        <input
          type="password"
          placeholder="Min 8 characters"
          {...register('password', { required: true, minLength: 8 })}
          autoComplete="new-password"
        />
        {errors.password && <ErrorHint>Password is required</ErrorHint>}
        <label htmlFor="confirm_password">Confirm Password</label>
        <input
          type="password"
          placeholder="Re-enter password"
          {...register('password_confirmation', {
            validate: (value) => value === getValues()['password']
          })}
          autoComplete="new-password"
        />
        {errors.password_confirmation && (
          <ErrorHint>Passwords don't match</ErrorHint>
        )}
        <label htmlFor="country">Country</label>
        <Controller
          render={({field}) =>
            <Select
              {...field}
              styles={stylesConfig}
              options={countries}
              placeholder="Select country"
            />
          }
          name="country"
          control={control}
          rules={{ required: true }}
        />
        <Button buttonStyle="secondary" type="submit" disabled={authLoading}>
          Sign Up
        </Button>
      </Form>
      {!!authError && (
        <ErrorBlock>
          {authError.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </ErrorBlock>
      )}
    </>
  );
};
