import React from 'react';
import styled from 'styled-components';
import { Header } from '../../components';

const Page = styled.section`
  text-align: left;
  color: #fff;

  ${Header} {
    padding-bottom: 2rem;
  }
`;

export const PrivacyPage = () => (
  <Page>
    <Header>Privacy Policy</Header>
    <p>
      This Privacy Policy ("Policy") governs the collection of personal data in
      connection with your use of the Ensydr Web Application (the "App") for all
      devices provided by [Ensydr Limited] (the "Company"). Under the Personal
      Data (Privacy) Ordinance (Cap. 486) (the "PDPO") and related laws and
      regulations, the Company has certain legal obligations regarding the
      personal data that we collect from you. This Policy is designed to give
      you useful information about the way we collect it, what we use it for,
      when we might disclose it and how you can manage your personal data.
    </p>
    <br />
    <p>
      By visiting Ensydr.com and using the App, you are accepting our Policy. If
      you do not agree to the terms of this Policy, please do not Signup to
      Ensydr App and exit Ensydr.com. We reserve the right to change the
      provisions of this Policy at any time. Your continued use of the App will
      mean that you accept any changes to the Policy from time to time.
    </p>
    <br />
    <p>
      We collect two basic types of data from you in conjunction with your use
      of the App, "Personal Data" and "Non-Personal Data".
    </p>
    <br />
    <p>
      "Personal Data" is any information that can individually identify you and
      includes your [name, e-mail address, username, telephone number, postal
      address, billing and contact information]. We will only collect and use
      your Personal Data for the purposes which are set out in this Policy.
    </p>
    <br />
    <p>
      "Non-Personal Data" includes aggregate and/or automatic information, which
      is data collected about the use of the App or about a group or category of
      users from which individual identities or other individually identifiable
      information has been removed. This Policy in no way restricts or limits
      our collection, use and provision of Non-Personal Data.
    </p>
    <br />
    <p>
      We may use your Personal Data and Non-Personal Data in the following ways:
    </p>
    <br />
    <p>
      Register, verify and manage your account on the App; facilitate your
      log-in access to the App, e.g. establish and maintain your logged-on
      identity in the App so that you can conveniently browse, access and use
      the App without being repeatedly requested to log-on; communicating with
      you about your account and sending you information about and/or features
      of the App; monitoring, customizing or improving the use of the App;
      facilitate the performance of our services offered on the App;
      administering the events/games organized on the App including informing
      you of a prize you have won; communicating with you about changes to our
      policies or terms; detecting, investigating and preventing activities that
      may violate our policies or be illegal;
    </p>
    <p>
      compile, process and generate statistics reports for the App, and
      performing statistical, demographic, and marketing analyses of users of
      the App; compile, maintain and update our database of account holders and
      users on the App; and use for our internal management or administrative
      purposes in relation to the App.
    </p>
    <br />
    <p>
      You shall provide true, accurate and updated Personal Data and shall not
      misrepresent yourself to be another individual or legal entity. Any
      changes in your Personal Data shall be notified to the Company immediately
      and in any case no later than 7 days from the date of such changes.
    </p>
    <br />
    <p>
      The Personal Data that you provided and/or will provide during your
      activity on the App may be disclosed by the Company to official
      authorities. The Company will make such disclosure only if required to be
      disclosed by the Company by applicable law, regulation or court order and
      to the minimum extent required.
    </p>
    <br />
    <p>
      You hereby confirm and agree that all or part of the information
      concerning Your Account and Transactions (including but not limited to
      Personal Data and Non-Personal Data), will be stored by the Company and
      may be used by the Company in case of dispute between you and the Company.
    </p>
    <br />
    <p>
      At its sole discretion, the Company may, but is not obliged, to review and
      inspect any information provided by the Client (including but not limited
      to Personal Data and Non-Personal Data) for any purpose. It is manifestly
      stated that, by downloading and using the App, you also agree that the
      Company holds neither commitment nor responsibility to you due to any
      aforesaid review or inspection of information.
    </p>
    <br />
    <p>
      The Company will take measures to implement advanced data protection
      procedures and to update them from time to time for the purpose of
      safeguarding your Personal Data and your Account. We will retain your
      Personal Data and Non-Personal Data for as long as it is necessary to
      fulfill the purposes for which they are to be used and erase any of your
      Personal Data and Non-Personal Data that we have stored as soon as
      reasonably practicable, subject to, or where otherwise required by law or
      permitted by law.
    </p>
    <br />
    <p>
      Upon registration on the App, you will be asked to choose a username and
      password to be used by you on each future login and for the performance of
      Transactions and use of the Company's services. In order to protect your
      privacy, sharing of the registration details (including without
      limitation, username and password) by you with other persons or business
      entities is strictly prohibited. The Company shall not be held liable for
      any damage or loss caused to you due to improper use (including prohibited
      and unprotected use) or storage of such username and password, including
      any such use made by a third party, and whether or not known to or
      authorized by you. Any access to your Account on the App with your
      username and password remains your sole responsibility. The Company shall
      not be held liable for any of such use. You are obliged to forthwith
      notify the Company of any suspicion for unauthorized use of your Account.
    </p>
    <br />
    <p>
      The Company does not store or collect any Credit Card data. In accordance
      with the recommendations of Payment Card Industry Security Standards
      Council, customer card details are protected using Transport Layer
      encryption - TLS 1.2 and application layer with algorithm AES and key
      length 256 bit.
    </p>
    <br />
    <p>
      Games or events run via the App will be subject to a set of rules. The
      rules for each game or event will specify how the information gathered
      from you for entry will be used and disclosed, if it is different to what
      is described in this Policy.
    </p>
    <br />
    <p>
      You should be aware that when you access the App, we may collect
      information by using cookies, web beacons or unique device identifiers to
      identify your device that is accessing the App. A cookie is a small amount
      of data that often includes a unique identifier that is sent to your
      computer or mobile phone (referred to here as a "device" browser from a
      website's computer and is stored on your device's hard drive for tracking
      site usage. A website may send its own cookie to your browser if your
      browser's preferences allow it, but, to protect your privacy, your browser
      only permits a website to access the cookies it has already sent to you,
      not the cookies sent to you by other websites. Many websites do this
      whenever a user visits their website in order to track online traffic
      flows. You may choose to configure your browser to reject cookies by
      modifying your browser settings or preferences.
    </p>
    <br />
    <p>
      During the course of any visit to the App by you, the pages viewed, along
      with a cookie are downloaded to your device. Cookies stored may determine
      the path you took on the App and used to anonymously identify repeat users
      of the App and what pages were most popular among our users. However, the
      Company protects your privacy by not storing your Personal Data. Using
      cookies is an industry standard and is currently used by most major
      websites. Stored cookies allow the Site to be more user-friendly and
      efficient for users by allowing the Company to learn about what
      information is more valued by our users versus what isn't.
    </p>
    <br />
    <p>
      You can stop all collection of information by the Company easily by
      uninstalling the App and/or deleting your Account on the App. We will
      remove your Personal Data and other preferences associated with your
      Account promptly after you delete your Account on the App.
    </p>
  </Page>
);
