import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import gql from 'graphql-tag';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';

import {
  BarChartIcon,
  Button,
  ButtonRow,
  CupIcon,
  GridIcon,
  Header,
  LoadingDots,
  P,
  Subtitle,
  TrendUpIcon
} from '../../components';
import { useUser } from '../../context';

const Page = styled.section`
  color: #fff;
  text-align: center;

  ${ButtonRow} {
    margin-top: 2rem;
    justify-content: center;

    ${Button} {
      min-width: unset;
      margin-left: 1rem;

      &:hover {
        color: ${(p) => p.theme.colors.primary};
      }
    }

    span {
      margin-left: 0.5rem;

      @media (max-width: 740px) {
        display: none;
      }
    }
  }
`;

const NewsSection = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin: 2rem 0;
  text-align: left;

  :first-of-type {
    margin-top: 3rem;
  }

  ${Subtitle} {
    text-align: left;
    color: #d1d5ff;
    margin-bottom: 0.25rem;
  }

  ${P} {
    white-space: pre-line;
  }

  ul {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  img {
    margin: 1.5rem;
    max-width: 520px;
    width: 100%;
  }
`;

const UpButton = styled(Button)`
  min-width: 1rem !important;
  font-size: 2rem;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.625rem;
  height: 3.375rem;
  width: 3.375rem;
`;

const NewsQuery = gql`
  query NewsQuery($first: Int, $after: String) {
    news(first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          date
          text
          image
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

type News = {
  id: string;
  date: string;
  text: string;
  image: string;
};

type NewsEdge = {
  cursor: string;
  node: News;
};

type PageInfo = {
  endCursor: string;
  startCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

export type NewsConnection = {
  edges: NewsEdge[];
  pageInfo: PageInfo;
};

export const NewsPage = () => {
  const navigate = useNavigate();
  const user = useUser();
  const { data, loading, fetchMore } = useQuery<{ news: NewsConnection }>(
    NewsQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: { first: 3 }
    }
  );
  const { edges, pageInfo } = data?.news ?? {};

  const loadNext = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          after: pageInfo?.endCursor
        }
      });
    }
  };

  return (
    <Page>
      <Header>Market News</Header>
      <P>Our take on what's happening in the market</P>
      {!user && (
        <ButtonRow>
          <Button
            buttonStyle="medium"
            shape="round"
            onClick={() => navigate('/assets')}
          >
            <GridIcon
              color="#f7f7f7"
              width={20}
              height={20}
              aria-hidden="true"
            />
            <span>Competitions</span>
          </Button>
          <Button
            buttonStyle="medium"
            shape="round"
            onClick={() => navigate('/about/win')}
          >
            <CupIcon
              color="#f7f7f7"
              width={20}
              height={20}
              aria-hidden="true"
            />
            <span>Win</span>
          </Button>
          <Button
            buttonStyle="medium"
            shape="round"
            onClick={() => navigate('/leaderboard')}
          >
            <BarChartIcon
              color="#f7f7f7"
              width={20}
              height={20}
              aria-hidden="true"
            />
            <span>Leaderboard</span>
          </Button>
          <Button
            buttonStyle="medium"
            shape="round"
            onClick={() => navigate('/activity')}
          >
            <TrendUpIcon
              color="#f7f7f7"
              width={20}
              height={20}
              aria-hidden="true"
            />
            <span>Activity</span>
          </Button>
        </ButtonRow>
      )}
      {edges?.map(({ node: { date, text, image, id } }: any) => (
        <NewsSection key={id}>
          <Subtitle>{format(new Date(date), 'd MMMM yyyy')}</Subtitle>
          <ReactMarkdown>{text}</ReactMarkdown>
          <img src={image} alt={`news`} />
        </NewsSection>
      ))}
      {pageInfo?.hasNextPage && (
        <Button
          buttonStyle="light"
          shape="round"
          disabled={loading}
          onClick={loadNext}
        >
          {loading ? <LoadingDots /> : 'Previous'}
        </Button>
      )}
      <UpButton
        buttonStyle="secondary"
        shape="round"
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        🔝
      </UpButton>
    </Page>
  );
};
