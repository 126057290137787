import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Header } from '../../components';
import {
  InstructionItem,
  InstructionsContainer,
  InstructionsList,
  Person
} from './InstructionSection';

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: 640px) {
    flex-direction: row;
    margin-top: 134px;
    margin-bottom: 7.75rem;
  }
`;

const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 33.875rem;
  max-width: 492px;
  margin-top: 10px;

  ${Button} {
    margin-top: 3rem;
    width: 16.9375rem;
    height: 3.4375rem;
    font-size: 1.25rem;
    line-height: 2.5rem;
    cursor: pointer;

    &:hover {
      background: linear-gradient(
        90deg,
        rgb(245, 145, 14) -47.09%,
        rgb(247, 146, 14) -11.48%,
        rgb(248, 186, 109) 165.93%,
        rgb(250, 187, 110) 165.93%
      );
    }
  }
`;

const Subtitle = styled.p`
  font-family: HelveticaNeue, Helvetica, sans-serif;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.375rem;
  color: #afb6d3;
  max-width: 426px;
`;

export const CTASection = () => (
  <StyledSection>
    <CTAContainer>
      <Header>Learn about Trading & Investments</Header>
      <Subtitle>
        Play and learn <i>risk-free</i>. Prove your knowledge, speculate and
        win!
      </Subtitle>
      <Link
        to="assets"
        as={Button}
        buttonStyle="secondaryGradient"
        shape="round"
      >
        Start Winning!
      </Link>
    </CTAContainer>
    <InstructionsContainer>
      <InstructionsList>
        <InstructionItem>Choose an Asset</InstructionItem>
        <InstructionItem>Predict the Price</InstructionItem>
        <InstructionItem>Score points for Accurate Predictions</InstructionItem>
      </InstructionsList>
      <Person />
    </InstructionsContainer>
  </StyledSection>
);
