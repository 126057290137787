import React from 'react';
import { CTASection } from './CTASection';
import { TrendsCard } from './TrendsCard';
import styled from 'styled-components';

const cryptos = ['BTC/USD', 'ETH/USD', 'SOL/USD'];

export const H1 = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  margin-bottom: 0.5rem;
  text-align: center;
  color: ${(p) => p.theme.colors.secondary};
`;

export const TrendsPage = () => (
  <>
    <CTASection />
    <H1>Ensydr Algorithm Predictions</H1>
    {cryptos.map((c) => (
      <TrendsCard code={c} />
    ))}
  </>
);
