import * as React from 'react';

export const CoinIcon = (props: any) => (
  <svg width={48} height={44} fill="none" viewBox="0 0 48 44" {...props}>
    <path
      fill="#F39C12"
      d="M44 22c0 12.15-9.85 22-22 22S0 34.15 0 22 9.85 0 22 0s22 9.85 22 22z"
    />
    <path
      fill="#F39C12"
      d="M23.793 29.243H1.37c.22.638.468 1.265.744 1.875h21.68v-1.875h-.001zm0-3.266H.517c.113.634.256 1.259.423 1.874h22.853v-1.874zm0 6.535H2.803c.348.645.727 1.27 1.136 1.875h19.855v-1.875h-.001zm0 3.267H4.963a22.204 22.204 0 001.676 1.874h17.156V35.78h-.002zm0 3.268H8.173c.835.688 1.72 1.315 2.65 1.875h12.97v-1.875zm0-16.339H.174c.02.632.066 1.257.137 1.875h23.482v-1.875zm0-17.731V3.102h-12.97a21.559 21.559 0 00-2.65 1.875h15.62zm0 1.393H6.638A22.2 22.2 0 004.96 8.246h18.832V6.371zm0 3.267H3.939a21.99 21.99 0 00-1.136 1.875h20.992V9.637h-.002zm0 34.199v-1.522H13.566a21.544 21.544 0 007.867 1.684 23.114 23.114 0 002.36-.162zM13.566 1.708h10.227V.187a23.087 23.087 0 00-2.36-.163c-2.783.068-5.436.66-7.867 1.684zm10.227 17.733H.31a22.555 22.555 0 00-.137 1.875h23.619v-1.875zm0-6.535H2.114c-.275.61-.524 1.235-.744 1.875h22.423v-1.875zm0 3.267H.94a21.4 21.4 0 00-.423 1.874h23.276v-1.874z"
      opacity={0.5}
    />
    <path
      fill="#F39C12"
      d="M23.759 38.69c-12.099 0-21.906-9.802-21.906-21.892 0-1.043.075-2.068.215-3.073a21.82 21.82 0 00-1.664 8.384C.404 34.199 10.21 44 22.309 44 33.364 44 42.505 35.817 44 25.18c-3.292 7.931-11.114 13.51-20.24 13.51z"
      opacity={0.5}
    />
    <path
      fill="#F1C40F"
      d="M47.633 22c0 12.15-9.85 22-22 22s-22-9.85-22-22 9.85-22 22-22 22 9.85 22 22z"
    />
    <path
      fill="#F39C12"
      d="M42.59 26.294c2.422-9.407-3.262-18.99-12.696-21.406C20.46 2.472 10.849 8.14 8.426 17.548c-2.422 9.407 3.262 18.99 12.696 21.406 9.434 2.415 19.045-3.252 21.468-12.66z"
    />
    <path
      fill="#F39C12"
      d="M36.315 8.073a17.466 17.466 0 014.611 11.841c0 9.704-7.9 17.57-17.645 17.57a17.622 17.622 0 01-10.767-3.652 17.629 17.629 0 0013.034 5.728c9.744 0 17.645-7.867 17.645-17.569 0-5.667-2.695-10.706-6.878-13.918z"
      opacity={0.5}
    />
    <path
      fill="#F39C12"
      fillRule="evenodd"
      d="M5.651 22c0 11.019 9.054 19.982 20.184 19.982 11.128 0 20.181-8.963 20.183-19.982 0-11.018-9.053-19.982-20.183-19.982C14.705 2.018 5.65 10.981 5.65 22zm.583 0c0-10.7 8.793-19.405 19.6-19.405 10.81 0 19.602 8.705 19.602 19.405 0 10.7-8.792 19.405-19.6 19.405-10.808 0-19.6-8.705-19.6-19.405z"
      clipRule="evenodd"
    />
    <g fill="#F1C40F">
      <path d="M16.166 25c.152-.226.323-.451.513-.677.19-.226.418-.425.684-.599.266-.174.557-.317.874-.43.317-.113.69-.169 1.121-.169.836 0 1.59.091 2.261.273a24.99 24.99 0 011.938.612c.62.226 1.254.43 1.9.612a7.894 7.894 0 002.147.274c.633 0 1.229-.074 1.786-.222a6.282 6.282 0 001.501-.598A6.46 6.46 0 0033 22.162L30.834 20l-.684.677a4.71 4.71 0 01-.741.599 4.119 4.119 0 01-.874.43 3.091 3.091 0 01-1.045.169 7.412 7.412 0 01-1.9-.273 45.767 45.767 0 01-2.09-.612 39.19 39.19 0 00-2.128-.612 7.971 7.971 0 00-1.976-.274 7.18 7.18 0 00-1.805.222c-.57.147-1.09.347-1.558.598a5.9 5.9 0 00-1.235.873c-.355.33-.62.677-.798 1.041L16.166 25z" />
      <path d="M33 33v-3.882h-9.945V15.882h9.738V12H19v21h14z" />
    </g>
  </svg>
);
