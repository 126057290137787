import * as React from 'react';

export const GridIcon = (props: any) => (
  <svg width={42} height={42} viewBox="0 0 42 42" {...props}>
    <g transform="translate(-4 -4)" fill="none" fillRule="evenodd">
      <path
        d="M8.333 8.333V18.75H18.75V8.333H8.333zm35.417 18.75c1.15 0 2.083.933 2.083 2.084V43.75c0 1.15-.932 2.083-2.083 2.083H29.167a2.083 2.083 0 01-2.084-2.083V29.167c0-1.15.933-2.084 2.084-2.084H43.75zm-22.917 0c1.15 0 2.084.933 2.084 2.084V43.75c0 1.15-.933 2.083-2.084 2.083H6.25a2.083 2.083 0 01-2.083-2.083V29.167c0-1.15.932-2.084 2.083-2.084h14.583zm20.834 4.167H31.25v10.417h10.417V31.25zm-22.917 0H8.333v10.417H18.75V31.25zm2.083-27.083c1.15 0 2.084.932 2.084 2.083v14.583c0 1.15-.933 2.084-2.084 2.084H6.25a2.083 2.083 0 01-2.083-2.084V6.25c0-1.15.932-2.083 2.083-2.083h14.583zm22.917 0c1.15 0 2.083.932 2.083 2.083v14.583c0 1.15-.932 2.084-2.083 2.084H29.167a2.083 2.083 0 01-2.084-2.084V6.25c0-1.15.933-2.083 2.084-2.083H43.75zm-2.083 4.166H31.25V18.75h10.417V8.333z"
        fill={props.color || 'currentColor'}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
