import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Row } from '../LeaderboardPage/LeaderTable';
import { ChevronDownIcon } from '../../components/SvgComponents';

const Section = styled.section`
  margin-bottom: 3.125rem;

  ${Row} {
    td:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    td:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;

const Title = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const H2 = styled.h2`
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 1.5;
  color: #f7f7f7;
  margin: 0;
`;

const NavLink = styled(Link)`
  border: 3px solid #f69b26;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  border-radius: 25px;
  padding: 6px 20px;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  text-decoration: none;
  color: #f7f7f7;

  svg {
    margin-left: 5px;
    transform: rotate(-90deg);
  }
  path {
    fill: #f7f7f7 !important;
  }
`;

type Props = {
  title: string;
  link: string;
  linkTitle?: string;
};

export const DashboardSection: FC<Props> = ({
  title,
  link,
  linkTitle,
  children
}) => {
  return (
    <Section>
      <Title>
        <H2>{title}</H2>
        <NavLink to={link}>
          {linkTitle || 'View More'}
          <ChevronDownIcon color="#F7F7F7" height={10} width={10} />
        </NavLink>
      </Title>
      {children}
    </Section>
  );
};
