import * as React from 'react';

export const ChevronDownIcon = (props: any) => (
  <svg width={30} height={18} viewBox="0 0 30 18" {...props}>
    <g transform="translate(-10 -16)" fill="none" fillRule="evenodd">
      <path d="M25 28.304L13.973 17.277a2.083 2.083 0 10-2.946 2.946l12.5 12.5a2.083 2.083 0 002.946 0l12.5-12.5a2.083 2.083 0 10-2.946-2.946L25 28.304z" />
      fillRule="nonzero" fill={props.color || '#000'}
    </g>
  </svg>
);
