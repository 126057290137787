import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import { Header } from '../../components';
import { ActivityBox } from '../ActivityPage/ActivityBox';
import { LeaderTable } from '../LeaderboardPage/LeaderTable';
import { useUser } from '../../context/AuthContext';
import { AssetsRibbon } from './AssetsRibbon';
import { DashboardSection } from './DashboardSection';
import { ActivityEntry } from '../ActivityPage/ActivityEntry';
import {
  Activity,
  createActivityList
} from '../ActivityPage/createActivityList';

const Page = styled.article`
  color: #fff;
  text-align: center;
  margin-bottom: 50px;

  @media (min-width: 640px) {
    padding: 50px 0;
  }

  ${Header} {
    padding-bottom: 2rem;
  }

  p {
    margin: 0;
  }
`;

const ActivityQuery = gql`
  query ActivityQuery($period: Period) {
    activity(period: $period) {
      updatedAt
      code
      amount
      coins
      contest {
        endDate
        finalPrice
      }
    }
  }
`;

const LeadersQuery = gql`
  query leaders {
    leadersSlice {
      rank
      id
      name
      coins
      country
    }
  }
`;

type Leaderboard = Array<{
  rank: number;
  id: string;
  name: string;
  coins: number;
  country: string;
}>;

export const DashboardPage = () => {
  const user = useUser();
  const navigate = useNavigate();

  // TODO: will need something more general protecting pages requiring authentication
  useEffect(() => {
    if (!user) navigate('/');
  }, [user, navigate]);

  const { data, loading, error } = useQuery<{
    leadersSlice: Leaderboard;
  }>(LeadersQuery, {
    fetchPolicy: 'cache-and-network'
  });

  const { data: activityData, loading: activityLoading } = useQuery<{
    activity: Activity[];
  }>(ActivityQuery, {
    variables: { period: 'MONTH' },
    fetchPolicy: 'cache-and-network'
  });

  const activity = activityData?.activity ?? [];
  const activityList = createActivityList(activity);

  const leaderboard = data?.leadersSlice ?? [];

  return (
    <Page>
      <DashboardSection title="Open Assets" link="/assets">
        <AssetsRibbon />
      </DashboardSection>

      <DashboardSection
        title="Activity"
        link="/activity"
        linkTitle="See Full History"
      >
        <ActivityBox
          activity={activityList}
          size="small"
          loading={activityLoading}
          renderItem={({ item, index }) => (
            <ActivityEntry {...(item as any)} key={index} />
          )}
        />
      </DashboardSection>

      {error ? null : (
        <DashboardSection title="Leaderboard" link="/leaderboard">
          {loading ? null : (
            <LeaderTable
              showHead={false}
              users={leaderboard}
              highlight={user?.id}
            />
          )}
        </DashboardSection>
      )}
    </Page>
  );
};
