import React from 'react';
import styled from 'styled-components';
import { Header, H2, P } from '../../components';
import { PrizesSection } from '../HomePage/PrizesSection';

const Page = styled.section`
  color: #fff;
  text-align: center;

  @media (min-width: 640px) {
    padding: 90px;
  }

  ${Header} {
    padding-bottom: 2rem;
  }

  article {
    text-align: left;

    :last-of-type {
      margin-bottom: 2rem;
    }
  }
`;

const notes = [
  {
    title: 'How can you win prizes on Ensydr Plaftorm?',
    text:
      'You can win prizes in two main ways, by reaching certain milestones or getting on the leaderboard.'
  },
  {
    title: 'Milestones (ETA Feb 2020)',
    text:
      'Everytime you reach a milestone, for example: 5000 points, you will be eligible to exchange those points for a prize. Currently there are no milestone prizes, they will be made available as the remaining platform is built.'
  },
  {
    title: 'Monthly Leaderboard winners coming Jan 31 2021',
    text:
      'On the last day of each month the top 3 players from the leaderboard will win a prize, for example - Keyboard, Gaming mouse, Headset, Amazon voucher etc. This Monthly leaderboard will reset every month.'
  },
  {
    title: 'Next Monthly Leaderboard prize draw:',
    text: '31st Jan 2021'
  }
];

export const PrizesPage = () => (
  <Page>
    <Header>Prizes</Header>
    {notes.map(({ title, text }) => (
      <article>
        <H2>{title}</H2>
        <P>{text}</P>
      </article>
    ))}
    <PrizesSection />
  </Page>
);
