import styled from 'styled-components';

export const P = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 0;
  color: #F7F7F7;
`;
