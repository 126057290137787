import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import {
  Button,
  InfoSubtitle,
  StyledPanel as BidPanel
} from '../../components';
import { Row, Table } from '../LeaderboardPage/LeaderTable';
import { AssetPicture } from '../BidPage/AssetPicture';

type Props = {
  code: string;
};

const StyledPanel = styled(BidPanel)`
  @media (min-width: 640px) {
    div:last-child {
      width: 55%;
    }
  }

  ${InfoSubtitle} span {
    font-size: 2rem;
  }
`;

const StyledTable = styled(Table)`
  ${Row} {
    height: 3.5rem;
    margin-bottom: 0.5rem;

    td {
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding-left: 2rem;

      &:first-child {
        border-radius: 10px 0 0 10px;
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
        text-align: center;
      }
    }
  }
`;

const TrendText = styled.span<{ trend: string }>`
  color: ${({ trend, theme }) =>
    trend === 'UP'
      ? theme.colors.success
      : trend === 'DOWN'
      ? theme.colors.primary
      : theme.colors.secondary};
`;

const ListTrends = gql`
  query ListTrends($code: String!) {
    asset(code: $code) {
      price
    }
    trends(code: $code)
  }
`;

const days = ['tomorrow', 'in 2 days', 'in a week'];

export const TrendsTable = ({
  trends,
  price
}: {
  trends: string[];
  price: number;
}) => (
  <div>
    <InfoSubtitle>
      Current price:
      <span> ${price}</span>
    </InfoSubtitle>
    <StyledTable>
      <tbody>
        {trends.map((trend: string, i: number) => (
          <Row key={i}>
            <td>{days[i]}</td>
            <td>
              {trend ? (
                <>
                  <TrendText trend={trend}>{trend}</TrendText>{' '}
                  {(trend === 'UP' && '🔼') || (trend === 'DOWN' && '🔽')}
                </>
              ) : (
                <Link
                  to="/login"
                  as={Button}
                  buttonStyle="secondaryGradient"
                  shape="round"
                >
                  Sign in to see
                </Link>
              )}
            </td>
          </Row>
        ))}
      </tbody>
    </StyledTable>
  </div>
);

export const TrendsCard = ({ code }: Props) => {
  const { data, error, loading } = useQuery(ListTrends, {
    variables: { code },
    fetchPolicy: 'cache-and-network'
  });

  console.log(data);
  const { asset, trends } = data || {};

  return !asset ? (
    <>Loading...</>
  ) : (
    <StyledPanel data-loading={loading}>
      <AssetPicture small>
        <img src={`/assets/${code.split('/').join('-')}.png`} alt={code} />
      </AssetPicture>
      <TrendsTable trends={trends} price={asset.price} />
    </StyledPanel>
  );
};
