import { format, formatDistanceToNowStrict, parseISO } from 'date-fns';
import { assetList } from '../AssetsPage/assetList';

export type Activity = {
  updatedAt: string;
  code: string;
  amount: number;
  coins: number;
  user?: { name: string };
  contest: {
    endDate: string;
    finalPrice: number;
  };
};
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

export const createActivityList = (activity: Activity[]): any => {
  const result = activity
    .map(({ updatedAt, code, amount, coins, contest, ...rest }) => ({
      at: formatDistanceToNowStrict(parseISO(updatedAt)),
      text: coins
        ? `Scored ${coins} Ensydr points for predicting `
        : 'Predicted ',
      amount: amount > 1 ? formatter.format(amount) : `$${amount}`,
      asset: `${assetList[code]} (${code})`,
      endDate: format(parseISO(contest.endDate), 'LLL do'),
      finalPrice: Number(contest.finalPrice),
      ...rest
    }))
    .reduce((list: any, { at, user, ...rest }) => {
      const key = `${at}_${user?.name ?? ''}`;
      if (!list[key]) list[key] = { at, user, activities: [] };
      list[key].activities.push({ ...rest });
      return list;
    }, {});

  return Object.values(result).sort((a: any, b: any) => a.at - b.at);
};
