import usePortal from 'react-cool-portal';
import { Link } from 'react-router-dom';
import { ButtonRow, Modal } from '../Modal/Modal';
import { Button } from '../Button/Button';

type SuccessBidModalProps = {
  asset?: {
    name: string;
    type: 'STOCK' | 'CRYPTO';
  };
  bid: string | number;
};

export const useSuccessBidModal = () => {
  const { Portal, show, hide, isShow } = usePortal({
    containerId: 'success-bid-portal',
    defaultShow: false,
    internalShowHide: false
  });

  const SuccessBidModal = ({ asset, bid }: SuccessBidModalProps) => {
    const { name, type } = asset ?? {};

    return (
      <Portal>
        <Modal title="Success!" type="success" hide={false} isShow={isShow}>
          <p>
            You predicted that {name}
            {type === 'STOCK' && ' shares'} price will change to ${bid}
          </p>
          <ButtonRow>
            <Button shape="sharp" buttonStyle="ghost" onClick={() => hide()}>
              Close
            </Button>
            <Link as={Button} shape="sharp" buttonStyle="primary" to="/assets">
              Select next
            </Link>
          </ButtonRow>
        </Modal>
      </Portal>
    );
  };

  return {
    SuccessBidModal,
    showSuccessBidModal: show
  };
};

export const useNoUserModal = () => {
  const { Portal, show, hide, isShow } = usePortal({
    containerId: 'nouser-portal',
    defaultShow: false,
    internalShowHide: false
  });

  const NoUserModal = () => (
    <Portal>
      <Modal title="Opps!" type="alert" hide={hide} isShow={isShow}>
        <p>Login or Sign Up to submit prediction</p>
        <ButtonRow>
          <Link as={Button} shape="sharp" buttonStyle="ghost" to="/login">
            Login
          </Link>
          <Link
            as={Button}
            shape="sharp"
            buttonStyle="alert"
            to="/login/signup"
          >
            Sign up
          </Link>
        </ButtonRow>
      </Modal>
    </Portal>
  );

  return {
    NoUserModal,
    showNoUserModal: show
  };
};
