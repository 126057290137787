import gql from 'graphql-tag';

export const ActivityFragment = gql`
  fragment ActivityFragment on Activity {
    updatedAt
    code
    amount
    coins
    user {
      name
      avatar
    }
    contest {
      endDate
      finalPrice
    }
  }
`;

export const AllActivityQuery = gql`
  query AllActivityQuery($first: Int, $after: String) {
    allActivity(first: $first, after: $after) {
      edges {
        cursor
        node {
          ...ActivityFragment
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
  ${ActivityFragment}
`;
