import React from 'react';
import styled from 'styled-components';
import { Bookmark, ESymbol, Header } from '../../components';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Leaderboard, LeaderTable } from './LeaderTable';
import { Coins, Name, Top, TopRanks } from './TopRanks';
import { WinnersSection } from './WinnersSection';

const Page = styled.section`
  color: #fff;
  text-align: center;
  margin-bottom: 50px;

  @media (min-width: 640px) {
    padding: 50px 90px;
  }

  ${Header} {
    padding-bottom: 2rem;
    
    &:not(:first-child) {
      padding-top: 2rem;
    }
  }
`;

const LeaderboardQuery = gql`
  query Leaderboard {
    leaderboard {
      rank
      name
      coins
      country
      avatar
    }
  }
`;

export const LeaderboardPage = () => {
  const { data, loading, error } = useQuery<{ leaderboard: Leaderboard }>(
    LeaderboardQuery,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  const leaderboard = data?.leaderboard ?? [];
  const top = leaderboard.slice(0, 3);
  const rest = leaderboard.slice(3, leaderboard.length);

  return (
    <Page>
      <WinnersSection />
      <Header>Leaderboard</Header>
      {loading || error ? null : (
        <>
          <TopRanks>
            {top.map(({ rank, name, coins, avatar }) => (
              <Top key={rank}>
                <Bookmark rank={rank} />
                <img src={avatar || '/icons/user.svg'} alt="avatar" />
                <Name>{name}</Name>
                <Coins>
                  <ESymbol />
                  {coins}
                </Coins>
              </Top>
            ))}
          </TopRanks>
          <LeaderTable users={rest} />
        </>
      )}
    </Page>
  );
};
