import React from 'react';
import styled from 'styled-components';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Subtitle } from '../../components';

const Person = styled.img.attrs(() => ({
  src: '/shapes/orange.svg',
  alt: 'Watching'
}))`
  @media (min-width: 640px) {
    margin-top: 5rem;
  }
`;

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;

  @media (min-width: 640px) {
    margin: 3.4375rem 3.75rem;
    flex-direction: row;
  }

  a {
    text-decoration: none;
  }
`;

const LinkTitle = ({ children, ...props }: any) => (
  <Link {...props}>
    <Subtitle>{children}</Subtitle>
  </Link>
);

const StyledLinkTitle = styled(LinkTitle)`
  color: #fff;
  width: max-content;
  padding: 0 2.5rem 0.75rem;
  border-bottom: 4px solid;
  transition: color 0.5s ease;

  &&[aria-current='true'] {
    pointer-events: none;
    color: ${p => p.theme.colors.primary};

    ${Subtitle} {
      color: ${p => p.theme.colors.primary};
      transition: color 0.5s ease;
    }
  }
  &:hover {
    color: ${p => p.theme.colors.primary};
  }
`;

const PageTitle = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 2.125rem;

  @media (min-width: 640px) {
    margin-top: 2.5625rem;
  }
`;

const isActive = (location: string, path: string): boolean =>
  location.includes(path);

export const LoginPage = () => {
  const { pathname } = useLocation();
  return (
    <StyledSection>
      <div>
        <PageTitle>
          <StyledLinkTitle aria-current={pathname === '/login'} to="/login">
            Login
          </StyledLinkTitle>
          <StyledLinkTitle
            aria-current={isActive(pathname, 'signup')}
            to="/login/signup"
          >
            Sign&nbsp;Up
          </StyledLinkTitle>
        </PageTitle>
        <Outlet />
      </div>
      <Person />
    </StyledSection>
  );
};
