import * as React from 'react';

export const AwardIcon = (props: any) => (
  <svg width={34} height={50} viewBox="0 0 34 50" {...props}>
    <g transform="translate(-8)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <path
          d="M25 0c9.205 0 16.667 7.462 16.667 16.667 0 5.395-2.564 10.19-6.539 13.237l2.354 17.739c.23 1.73-1.641 2.958-3.137 2.06L25 44.096l-9.345 5.607c-1.496.898-3.367-.331-3.137-2.06l2.356-17.738c-3.976-3.046-6.54-7.842-6.54-13.238C8.333 7.462 15.794 0 25 0zm0 33.333a16.62 16.62 0 01-6.218-1.198l-1.564 11.772 6.71-4.027a2.083 2.083 0 012.144 0l6.711 4.027-1.562-11.774a16.62 16.62 0 01-6.221 1.2zm0-29.166c-6.904 0-12.5 5.596-12.5 12.5 0 6.903 5.596 12.5 12.5 12.5 2.43 0 4.699-.694 6.618-1.894.098-.079.205-.147.318-.206a12.487 12.487 0 005.564-10.4c0-6.904-5.596-12.5-12.5-12.5z"
          id="a"
        />
      </mask>
      <g mask="url(#b)" fill={props.color || 'currentColor'}>
        <path d="M0 0h50v50H0z" />
      </g>
    </g>{' '}
  </svg>
);
