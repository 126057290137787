import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { P } from '../Text';
import { AssetPicture } from '../../pages/BidPage/AssetPicture';
import Graph from './Graph';
import { BidInput } from './BidInput';
import { CurrentPrice } from './CurrentPrice';
import { CurrentBids } from './CurrentBids';

const gradientAnimation = keyframes`
  0% {
    background-position: 1% 0;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 1% 0;
  }
`;

export const loadingStyle = css`
  background: linear-gradient(314deg, #d6d7d7, #51546f, #71dbc8);
  background-size: 600% 600%;
  animation: ${gradientAnimation} 8s ease infinite;
  min-height: 400px;

  @media (min-width: 800px) {
    width: 800px;
  }
`;

export const InfoWrapper = styled.div<{ wide?: boolean }>`
  overflow-y: scroll;
  max-height: 20rem;
  width: 100%;
  z-index: 10;

  @media (min-width: 640px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    width: ${(p) => (p.wide === false ? 40 : 60)}%;
  }
`;

export const StyledPanel = styled.div<{ 'data-loading': boolean }>`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  margin-top: 1.625rem;
  margin-bottom: 2.125rem;
  padding: 1rem 2.25rem;
  mix-blend-mode: normal;
  border-radius: 25px;
  text-align: left;

  backdrop-filter: blur(10px);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  background: inherit;
  position: relative;
  overflow: hidden;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 2000px rgba(247, 247, 247, 0.7);
    filter: blur(10px);
    background: inherit;
    z-index: -10;
  }

  &[data-loading='true'] {
    ${loadingStyle}
  }

  @media (min-width: 640px) {
    max-height: 410px;
  }

  ${P} {
    align-self: flex-start;
  }
`;

export const InfoSubtitle = styled.p`
  font-style: normal;
  font-weight: normal;
  color: #f7f7f7;
  padding-top: 0.75rem;
  margin: 0 0 0.625rem;
  font-size: 25px;
  line-height: 30px;
`;

const adjustTimeseries = (
  timeseries: [{ price: string; date?: string }],
  coefficient: number = 1
) => {
  const numbered =
    timeseries?.map(({ price }) => ({
      price: Number(price)
    })) ?? [];
  const min = Math.min(...numbered.map(({ price }) => price));

  return numbered.map(({ price }) => ({ price: (price - min) * coefficient }));
};

type Props = any; // TODO

export const BidPanel = ({
  image,
  contest,
  loading,
  error,
  onSubmit,
  submitting
}: Props) => {
  const { asset, bids } = contest;
  const { price, name, timeseries } = asset ?? {};

  const graphData = adjustTimeseries(timeseries, price < 1 ? 1000 : 1);

  return (
    <StyledPanel data-loading={loading}>
      {!(loading || error) && (
        <>
          <AssetPicture small={!!bids}>
            <img src={image} alt={name} />
            {graphData.length && <Graph data={graphData} />}
          </AssetPicture>

          <CurrentPrice price={price} />
          <InfoWrapper wide={!!bids}>
            {bids ? (
              <CurrentBids contest={contest} />
            ) : (
              <BidInput
                contest={contest}
                onSubmit={onSubmit}
                submitting={submitting}
              />
            )}
          </InfoWrapper>
        </>
      )}
    </StyledPanel>
  );
};
