import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Layout from './Layout';
import {
  AboutPage,
  ActivityPage,
  AssetsPage,
  BidPage,
  CommunityContestPage,
  ContactPage,
  DashboardPage,
  EmailConfirmed,
  FAQPage,
  ForgotForm,
  HomePage,
  ProfilePage,
  LeaderboardPage,
  LoginForm,
  LoginPage,
  NewsPage,
  PrivacyPage,
  PrizesPage,
  RoadmapPage,
  SignupForm,
  TermsPage,
  TrendsPage,
  UpdatePasswordForm,
  WeeklyContestPage,
  WinPage
} from './pages';

const Router = () => (
  <Routes>
    <Route path="//*" element={<Layout />}>
      <Route path="/" element={<HomePage />} />
      <Route path="leaderboard" element={<LeaderboardPage />} />
      <Route path="assets" element={<AssetsPage />} />
      <Route path="bid/:code" element={<BidPage />} />
      <Route path="weekly" element={<WeeklyContestPage />} />
      <Route path="community" element={<CommunityContestPage />} />

      <Route path="login" element={<LoginPage />}>
        <Route path="/" element={<LoginForm />} />
        <Route path="signup" element={<SignupForm />} />
        <Route path="confirm/:token" element={<EmailConfirmed />} />
        <Route path="forgot" element={<ForgotForm />} />
        <Route path="reset/:token" element={<UpdatePasswordForm />} />
      </Route>

      <Route path="dashboard" element={<DashboardPage />} />
      <Route path="activity" element={<ActivityPage />} />
      <Route path="profile" element={<ProfilePage />} />
      <Route path="news" element={<NewsPage />} />

      <Route path="trends" element={<TrendsPage />} />

      <Route path="about">
        <Route path="/" element={<AboutPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="faq" element={<FAQPage />} />
        <Route path="prizes" element={<PrizesPage />} />
        <Route path="win" element={<WinPage />} />
        <Route path="roadmap" element={<RoadmapPage />} />
        <Route path="privacy" element={<PrivacyPage />} />
        <Route path="terms" element={<TermsPage />} />
      </Route>

      <Route path="*" element={<HomePage />} />
    </Route>
  </Routes>
);

export default Router;

export function ScrollToTop() {
  const { pathname, key } = useLocation();

  useEffect(() => {
    if (key !== 'default') {
      window.scrollTo(0, 0);
    }
  }, [pathname, key]);

  return null;
}
