export const winners = [
  {
    name: 'Glimp.co.nz community',
    type: 'challenge',
    date: '2021-10-27T20:00Z',
    winner: 'Henry',
    prize: '1 Unique NFT'
  },
  {
    name: 'Most score 4 Oct - 19 Oct',
    type: 'score',
    date: '2021-10-25T23:00:00.000Z',
    winner: 'Freuseb',
    score: 1855,
    prize: '1 Ensydr NFT'
  },
  {
    name: 'Robinhood',
    code: 'HOOD',
    type: 'featured',
    date: '2021-09-22T20:00:00Z',
    winner: 'Frank Iacono',
    bid: {
      price: '43.35',
      date: '06 Sep',
      finalPrice: '46.88'
    },
    prize: '$25 Amazon Voucher'
  },
  {
    name: 'Most score 25 Aug - 6 Sep',
    type: 'score',
    date: '2021-09-12T23:00:00.000Z',
    winner: 'Henry',
    score: 1655,
    prize: '5 Cardano (ADA)'
  },
  {
    name: 'Most score 9 Aug - 20 Aug',
    type: 'score',
    date: '2021-08-18T23:00:00.000Z',
    winner: 'Henry',
    score: 1980,
    prize: 'Gaming Keyboard'
  },
  {
    name: 'DogeCoin',
    code: 'DOGE',
    type: 'featured',
    date: '2021-08-15T21:00:00.000Z',
    winner: 'Elongated Muskrat',
    bid: {
      price: '0.32',
      date: '11 Aug',
      finalPrice: '0.32025'
    },
    prize: 'Gaming Mouse'
  },
  {
    name: 'Ethereum',
    code: 'ETH',
    type: 'featured',
    date: '2021-08-01T21:00:00.000Z',
    winner: 'Freuseb',
    bid: {
      price: '2,453.27',
      date: '1 Aug',
      finalPrice: '2,454.63'
    },
    prize: 'Gaming Headset'
  },
  {
    name: 'Most score 18 July - 29 July',
    type: 'score',
    date: '2021-07-18T21:00:00.000Z',
    winner: 'Freuseb',
    score: 1885,
    prize: '$20 Netlix Voucher'
  },
  {
    name: 'GameStop',
    code: 'GME',
    type: 'featured',
    date: '2021-07-15T21:00:00.000Z',
    winner: 'Henry',
    bid: {
      price: '163.00',
      date: '15 July',
      finalPrice: '167.22'
    },
    prize: '25 DogeCoins'
  },
  {
    name: 'Most score 4 July - 15 July',
    type: 'score',
    date: '2021-07-18T21:00:00.000Z',
    winner: 'Henry',
    score: 2000,
    prize: '0.005 Ethereum'
  },

  {
    name: 'Bitcoin',
    code: 'BTC',
    type: 'featured',
    date: '2021-07-05T21:00:00.000Z',
    winner: 'Henry',
    bid: {
      price: '33,885.00',
      date: '5th July',
      finalPrice: '34,108.51'
    },
    prize: '0.5 Polkadot'
  },

  {
    name: 'Most score 27 June - 3 July',
    type: 'score',
    date: '2021-07-04T21:00:00.000Z',
    winner: 'Henry',
    score: 1335,
    prize: '$20 Amazon voucher'
  },
  {
    name: 'Most score 13 - 19 June',
    type: 'score',
    date: '2021-06-21T21:00:00.000Z',
    winner: 'Henry',
    score: 745,
    prize: '$20 Amazon voucher'
  },
  {
    name: 'AMC',
    code: 'AMC',
    type: 'featured',
    date: '2021-06-21T21:00:00.000Z',
    winner: 'Sammy',
    bid: {
      price: '53.50',
      date: '21st Jun',
      finalPrice: '55.60'
    },
    prize: '25 DogeCoins'
  },
  {
    name: 'Most score 23 May - 30 May',
    type: 'score',
    date: '2021-06-06T21:00:00.000Z',
    winner: 'Sammy',
    score: 745,
    prize: 'Gaming headset'
  },
  {
    name: 'Microsoft',
    code: 'MSFT',
    type: 'featured',
    date: '2021-06-05T21:00:00.000Z',
    winner: 'Soradbro',
    bid: {
      price: '251.80',
      date: '5th Jun',
      finalPrice: '250.65'
    },
    prize: 'Netflix Voucher'
  },
  {
    name: 'Most score 7 May - 14 May',
    type: 'score',
    date: '2021-05-18T21:00:00.000Z',
    winner: 'Heisenberg',
    score: 950,
    prize: '25 DogeCoins'
  },
  {
    name: 'Ethereum',
    code: 'ETH',
    type: 'featured',
    date: '2021-05-14T21:00:00.000Z',
    winner: 'Maclean Winiata',
    bid: {
      price: '3,727.00',
      date: '9th Mar',
      finalPrice: '3,713.54'
    },
    prize: '0.005 ETH'
  },
  {
    name: 'Most score 19 Apr - 25 Apr',
    type: 'score',
    date: '2021-04-27T21:00:00.000Z',
    winner: 'Heisenberg',
    score: 1050,
    prize: '1 Polkadot'
  },
  {
    name: 'Most score 5 Apr - 12 Apr',
    type: 'score',
    date: '2021-04-16T21:00:00.000Z',
    winner: 'Heisenberg',
    score: 1030,
    prize: '25 DogeCoins'
  },
  {
    name: 'Bitcoin',
    code: 'BTC',
    type: 'featured',
    date: '2021-03-16T21:00:00.000Z',
    winner: 'dmty',
    bid: {
      price: '56,320.00',
      date: '9th Mar',
      finalPrice: '55,799.96'
    },
    prize: '100 DogeCoins'
  },
  {
    name: 'Most score between 27 Mar - 03 Apr',
    type: 'score',
    date: '2021-04-07T21:00:00.000Z',
    winner: 'Heisenberg',
    score: 905,
    prize: '10 Cardano'
  },
  {
    name: 'Most score between 15 Mar - 21 Mar 2021',
    type: 'score',
    date: '2021-03-25T21:00:00.000Z',
    winner: 'Freuseb',
    score: 760,
    prize: '10 Cardano'
  },
  {
    name: 'Bitcoin',
    code: 'BTC',
    type: 'featured',
    date: '2021-03-16T21:00:00.000Z',
    winner: 'dmty',
    bid: {
      price: '56,320.00',
      date: '9th Mar',
      finalPrice: '55,799.96'
    },
    prize: '100 DogeCoins'
  },
  {
    name: 'Ethereum',
    code: 'ETH',
    type: 'featured',
    date: '2021-02-25T21:00:00.000Z',
    winner: 'Freuseb',
    bid: {
      price: '1,853.72',
      date: '22nd Feb',
      finalPrice: '1,628.94'
    },
    prize: '100 DogeCoins'
  },
  {
    name: 'DogeCoin',
    code: 'DOGE',
    type: 'featured',
    date: '2021-02-15T21:00:00.000Z',
    winner: 'Sam',
    bid: {
      price: '0.05438',
      date: '12th Feb',
      finalPrice: '0.05682'
    },
    prize: '$20 Amazon Voucher'
  },
  {
    name: 'GameStop',
    code: 'GME',
    type: 'featured',
    date: '2021-02-08T21:00:00.000Z',
    winner: 'Heisenberg',
    bid: {
      price: '66',
      date: '2nd Feb',
      finalPrice: '63.77'
    },
    prize: '$20 Amazon Voucher'
  },
  {
    name: 'First to 2000 Ensydr Points',
    type: 'challenge',
    date: '2021-01-28T21:00:00.000Z',
    winner: 'Heisenberg',
    prize: '$25 Amazon voucher'
  }
];
