import styled from 'styled-components';
import { P, Subtitle } from '../Text';
import React from 'react';

const StyledPrizeCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 280px;
  height: 280px;
  padding: 0 24px;
  background: rgba(86, 94, 174, 0.5);
  border-radius: 25px;
  backdrop-filter: blur(2px);
  mix-blend-mode: normal;
  cursor: pointer;

  img {
    width: 140px;
  }

  ${Subtitle} {
    font-size: 30px;
    line-height: 28px;
    text-align: center;
    color: #d1d5ff;
  }
  
  ${P} {
    color: #fff;
  }
`;

type Props = {
  image: string;
  title: string;
  condition?: string;
  onClick?: () => void;
}

export const PrizeCard = ({ image, title, condition, onClick }: Props) => (
  <StyledPrizeCard onClick={onClick}>
    <img src={image} alt={title} />
    <Subtitle>{title}</Subtitle>
    {!!condition && <P>{condition}</P>}
  </StyledPrizeCard>)
