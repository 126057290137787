import React, { useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import {
  Burger,
  Footer,
  Main,
  Menu,
  NavBar,
  useOnClickOutside
} from './components';
import { useUser } from './context';

export default function Layout() {
  const { pathname } = useLocation();
  const user = useUser();
  const flip = !!pathname.match(
    /(assets|bid|profile|dashboard|activity|community)/
  );
  const hideControls = !!pathname.match(/(login|register)/);

  const [open, setOpen] = useState(false);
  const node = useRef(null);

  useOnClickOutside(node, () => setOpen(false));

  return (
    <>
      <NavBar user={user} showInfo={flip} hideControls={hideControls} />
      {user && (
        <div ref={node}>
          <Burger open={open} setOpen={setOpen} aria-controls="main-menu" />
          <Menu open={open} setOpen={setOpen} id="main-menu" />
        </div>
      )}
      <Main flip={flip} menuOpen={open} loggedIn={!!user}>
        <Outlet />
      </Main>
      <Footer loggedIn={!!user} />
    </>
  );
}
