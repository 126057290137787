import React from 'react';
import styled from 'styled-components';
import { Header } from '../../components';
import { ActivityBox, ActivityConnection } from './ActivityBox';
import { useQuery } from '@apollo/client';
import { AllActivityQuery } from './queries';
import { ActivityEntry } from './ActivityEntry';
import { createActivityList } from './createActivityList';

const Page = styled.article`
  color: #fff;
  text-align: center;
  margin-bottom: 50px;

  @media (min-width: 640px) {
    padding: 50px 0;
  }

  ${Header} {
    padding-bottom: 2rem;
  }

  p {
    margin: 0;
  }
`;

export const ActivityPage = () => {
  const { data, loading, fetchMore } = useQuery<{
    allActivity: ActivityConnection;
  }>(AllActivityQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { first: 15 }
  });

  const { edges, pageInfo } = data?.allActivity ?? {};
  const activity = edges?.map((e) => e.node) ?? [];
  const activityList = createActivityList(activity);

  const loadNext = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          after: pageInfo?.endCursor
        }
      });
    }
  };

  return (
    <Page>
      <Header>Activity</Header>
      <ActivityBox
        activity={activityList}
        loading={loading}
        hasMore={!!pageInfo?.hasNextPage}
        fetchMore={loadNext}
        renderItem={({ item, index }) => (
          <ActivityEntry {...(item as any)} key={index} />
        )}
      />
    </Page>
  );
};
