import styled from 'styled-components';

export const AssetPicture = styled.div<{small?: boolean}>`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0;

  @media (min-width: 640px) {
    align-self: flex-start;
    width: ${p => p.small ? '40%' : '60%'};
    margin-right: 0.625rem;
    height: 0;
    padding-top: ${p => p.small ? '30%' : '44.3%'};
    background: #51546f;
    border-radius: 5px;
  }

  img {
    object-fit: contain;
    height: 100%;

    @media (min-width: 640px) {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;
