import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import ReactGA from 'react-ga';

import {
  BidPanel,
  Header,
  InfoSubtitle,
  P,
  PrizeCard,
  useNoUserModal,
  useSuccessBidModal
} from '../../components';

import { AssetsSection } from './AssetsSection';
import {
  Decor as WeeklyDecor,
  DecorTitle as WeeklyDTitle,
  Panel,
  WinTitle
} from './WeeklyPanel';
import { useUser } from '../../context';
import styled from 'styled-components';
import { Tab, TabList, TabPanel, Tabs } from '../BidPage/ActivityTabs';
import { ActivityContainer } from '../ActivityPage/ActivityBox';
import { format } from 'date-fns';
import { PlaceBid } from '../BidPage/queries';

const Page = styled(AssetsSection)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Decor = styled(WeeklyDecor)`
  border-bottom-color: ${(p) => p.theme.colors.primary};
`;

const DecorTitle = styled(WeeklyDTitle)`
  left: 1rem;
`;

const PanelTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 0.5rem;

  & > p {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.15;
    margin: 0;
  }

  & > a {
    text-decoration: none;

    &:hover > p {
      color: ${(p) => p.theme.colors.primary};
    }
  }
`;

const ContestLogo = styled.img`
  width: 100%;
  margin: -2.75rem 0;
`;

const Title = styled(WinTitle)`
  display: flex;
  align-items: center;
  align-self: center;
  padding: 0;

  img {
    width: 2.5rem;
  }
`;

export const GroupBanner = () => (
  <Panel>
    <PanelTitle>
      <ContestLogo src="/community/glimp-logo.png" alt="logo" />
      <a href="https://www.glimp.co.nz" target="_blank" rel="noreferrer">
        <InfoSubtitle>www.glimp.co.nz</InfoSubtitle>
      </a>
      <a
        href="https://www.tiktok.com/@dentyurkov"
        target="_blank"
        rel="noreferrer"
      >
        <Title>
          <img src="/community/tiktok-logo.png" alt="tiktok" />
          @dentyurkov
        </Title>
      </a>
    </PanelTitle>
    <Decor />
    <DecorTitle>Limited time</DecorTitle>
  </Panel>
);

const Prizes = styled.article`
  display: grid;
  column-gap: 2rem;
  row-gap: 2rem;
  margin-bottom: 2rem;

  @media (min-width: 640px) {
    justify-items: center;
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Rules = styled(ActivityContainer)`
  text-align: left;

  ${InfoSubtitle} {
    font-style: normal;
    font-weight: bold;
    font-size: 1.75rem;
    line-height: 2rem;
    color: #d1d5ff;

    &::before {
      content: '•';
      color: ${(p) => p.theme.colors.secondary};
      display: inline-block;
      font-weight: bold;
      font-size: 2rem;
      width: 1em;
      margin-left: -1em;
    }
  }

  ul {
    list-style: none;

    ul {
      padding: 0;
    }

    a {
      color: #c7c7c7;
      text-decoration: none;

      &:hover {
        color: ${(p) => p.theme.colors.primary};
      }
    }
  }

  li {
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #f7f7f7;

    li::before {
      content: '\\2022';
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
`;

const prizes = [
  {
    image: '/win/nft.png',
    title: '1 Unique NFT',
    condition: '1st Prize'
  },
  {
    image: '/win/doge.png',
    title: '50 Doge Coins',
    condition: '2nd Prize'
  },
  {
    image: '/win/eth.png',
    title: '$20 in ETH',
    condition: '3rd Prize Prize'
  }
];

const startDate = '2021-10-04T00:00Z';
const closeDate = '2021-10-18T20:00Z';
const endDate = '2021-10-22T20:00Z';

const ListContests = gql`
  query groupContests {
    listGroup {
      id
      startDate
      endDate
      closeDate
      bid
      bids {
        amount
        user {
          id
          name
        }
      }
      type
      asset {
        code
        name
        price
        type
        timestamp
        timeseries {
          price
        }
      }
    }
  }
`;

export const CommunityContestPage = () => {
  const user = useUser();

  const { data, error, loading } = useQuery(ListContests, {
    fetchPolicy: 'cache-and-network'
  });
  const [placeBid, mutation] = useMutation(PlaceBid, {
    refetchQueries: ['groupContests']
  });

  const [currentAsset, setAsset] = useState();

  const { SuccessBidModal, showSuccessBidModal } = useSuccessBidModal();
  const { NoUserModal, showNoUserModal } = useNoUserModal();

  const submitPrediction = async ({ id, price, asset }: any) => {
    try {
      if (user) {
        await placeBid({
          variables: {
            id,
            price,
            code: asset.code
          }
        });
        setAsset(asset);
        showSuccessBidModal();
        ReactGA.event({
          category: 'User',
          action: 'Place bid'
        });
      } else {
        showNoUserModal();
      }
    } catch (err) {
      // TODO: create a modal for generic error
      showNoUserModal();
    }
  };

  return (
    <Page>
      <Header>Play. Predict. Win.</Header>
      <InfoSubtitle>
        Best prediction gets higher score, highest score win prices!
      </InfoSubtitle>

      <GroupBanner />

      <Prizes>
        {prizes.map((prize, i) => (
          <PrizeCard key={i} {...prize} />
        ))}
      </Prizes>

      {data?.listGroup.map((contest: any) => {
        const { id, asset } = contest;

        return (
          <BidPanel
            key={id}
            image={`/assets/${asset.code.replace('/', '-')}-group.png`}
            contest={contest}
            loading={loading}
            onSubmit={({ price }: any) =>
              submitPrediction({ id, price, asset })
            }
            submitting={mutation.loading}
            error={error}
          />
        );
      })}

      <Tabs defaultIndex={1}>
        <TabList>
          <Tab>Rules</Tab>
        </TabList>

        <TabPanel>
          <Rules>
            <ul>
              <li>
                <InfoSubtitle>Competition dates</InfoSubtitle>
                <ul>
                  <li>Starts {format(new Date(startDate), 'PPPp')}</li>
                  <li>
                    Get your predictions in by{' '}
                    {format(new Date(closeDate), 'PPPp')}
                  </li>
                  <li>
                    Make a price prediction for{' '}
                    {format(new Date(endDate), 'PPPp')}
                  </li>
                  <li>
                    Winners will be announce by October 27th on Leaderboard page
                  </li>
                </ul>
              </li>
              <li>
                <InfoSubtitle>How to win</InfoSubtitle>
                <ul>
                  <li>
                    Make a prediction, the most accurate prediction wins 200
                    points for stock and 250 points for crypto, second most
                    accurate prediction wins 100/150 (stock/crypto), the third
                    most accurate prediction wins 50/70 (stock/crypto) and you
                    get 10 points for making a prediction
                  </li>
                  <li>Most points win the 1st, 2nd and 3rd prize</li>
                  <li>In event of a draw the prize will be split</li>
                  <li>
                    Do your own research and analysis and join our discord group
                    if you have any questions or need support
                  </li>
                </ul>
              </li>
              <li>
                <InfoSubtitle>Which NFT will I get</InfoSubtitle>
                <ul>
                  <li>
                    <a
                      href="https://nft.ensydr.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Check out here 👈
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <P></P>
          </Rules>
        </TabPanel>
      </Tabs>

      {currentAsset && (
        <SuccessBidModal
          bid={mutation.data?.placeBid?.bid ?? 0}
          asset={currentAsset}
        />
      )}
      <NoUserModal />
    </Page>
  );
};
