import * as React from 'react';
import styled from 'styled-components';

const ESymbolSvg = (props: any) => (
  <svg width={13} height={15} fill="none" viewBox="0 0 13 15" {...props}>
    <path
      fill={props.color || '#F7F7F7'}
      d="M1.482 8.895a5.29 5.29 0 01.351-.464c.13-.154.286-.29.468-.41a2.84 2.84 0 01.598-.293c.217-.078.472-.116.767-.116.572 0 1.088.062 1.547.187.46.125.901.264 1.326.419.425.154.858.294 1.3.418a5.4 5.4 0 001.469.187c.433 0 .84-.05 1.222-.151a4.3 4.3 0 001.027-.41A4.418 4.418 0 0013 6.952l-1.482-1.478-.468.463a3.223 3.223 0 01-.507.41 2.822 2.822 0 01-.598.294 2.117 2.117 0 01-.715.116c-.399 0-.832-.063-1.3-.188a31.327 31.327 0 01-1.43-.418 26.81 26.81 0 00-1.456-.419 5.455 5.455 0 00-1.352-.187 4.91 4.91 0 00-2.301.561c-.32.172-.602.371-.845.597A2.442 2.442 0 000 7.416l1.482 1.479z"
    />
    <path
      fill={props.color || '#F7F7F7'}
      d="M13 14.368v-2.656H6.196V2.656h6.662V0H3.421v14.368H13z"
    />
  </svg>
);

export const ESymbol = styled(ESymbolSvg)``;
