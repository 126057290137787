import React from 'react';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';

import { Header } from '../../components';
import {
  ActivityContainer,
  ActivityContent
} from '../ActivityPage/ActivityBox';
import { Entry } from '../ActivityPage/ActivityEntry';
import { winners } from '../../fixtures/winners';

const Title = styled.header`
  font-weight: 700;
  font-size: 22px;
  color: #d1d5ff;
  margin: 0;
`;

const Date = styled.p`
  font-size: 20px;
  color: #d1d5ff;
  margin: 0;
`;

const Text = styled.p`
  margin: 0;
`;

const StyledEntry = styled(Entry)`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  & > :first-child {
    width: 4rem;
    margin-right: 0.5rem;
  }

  & > :nth-child(2) {
    flex: 1;
  }
`;

export const WinnersSection = () => (
  <>
    <Header>Winners</Header>
    <ActivityContainer>
      <ActivityContent size="small">
        {winners.map(({name, code, type, date, winner, bid, score, prize}) => (<StyledEntry>
          {type === 'featured' && <img src="/shapes/cup.svg" alt="cup"/>}
          {type === 'score' && <img src="/shapes/coin.svg" alt="coin"/>}
          {type === 'challenge' && <img src="/shapes/first.svg" alt="medal"/>}
          <div>
            {type === 'featured' && <Title>
              {name} ({code}) Featured tournament prize winner is {winner}
            </Title>}
            {type === 'score' && <Title>
              {name} collected by {winner}
            </Title>}
            {type === 'challenge' && <Title>
              Winner of {name} challenge is {winner}
            </Title>}
            <Date>{format(parseISO(date), 'do MMM yyyy')}</Date>
            <Text>
              {bid && <>{winner} predicted ${bid.price} on {bid.date} for {code} {format(parseISO(date), 'do MMM')}{' '}
                <i>(${bid.finalPrice} market price)</i>
                <br/></>}

              {score && <>{winner} collected <b>{score}</b> Ensydr points<br/></>}

              Congratulations for winning {prize}
            </Text>
          </div>
        </StyledEntry>))}
      </ActivityContent>
    </ActivityContainer>
  </>
);
