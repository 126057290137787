import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import TextareaAutosize from 'react-textarea-autosize';
import { useHotkeys } from 'react-hotkeys-hook';

import { Button } from '../../components';
import { SendMessage } from './queries';

const MessageInputContainer = styled.div`
  position: relative;
`;

const Textarea = styled(TextareaAutosize)`
  width: 100%;
  margin: 1rem 1rem 0 0;
  padding-left: 0.5rem;
  padding-right: 3.125rem;
  background: #6f91f9b3;
  border-radius: 10px;
  border: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  color: #fdfdfd;
  font-size: 1.25rem;
  line-height: 1.75rem;

  ::placeholder {
    color: #e8e8e8;
  }
`;

const StyledButton = styled(Button)`
  position: absolute;
  right: 0.25rem;
  bottom: 0.5rem;
  border-radius: 25%;
  margin-top: 0.5rem;
  padding-top: 0.625rem;
  padding-left: 1rem;
  height: 3rem;
  width: 3rem;
  min-width: unset;
`;

const Hint = styled.small`
  position: absolute;
  right: 0;
  top: -0.25rem;
  color: #c5c5c5;
`;

export const MessageInput = ({ code }: { code: string }) => {
  const [message, setMessage] = useState<string>('');
  const [sendMessage, { loading }] = useMutation(SendMessage);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };
  const handleClick = () => {
    sendMessage({ variables: { code, message } })
      .then(() => setMessage(''))
      .catch(console.log);
  };

  useHotkeys(
    'shift+enter',
    (e) => {
      e.preventDefault();

      if (message) handleClick();
    },
    { enableOnTags: ['TEXTAREA'] },
    []
  );

  return (
    <MessageInputContainer>
      <Textarea
        minRows={2}
        maxRows={5}
        placeholder="What's on your mind?"
        value={message}
        onChange={handleChange}
      />
      <StyledButton
        buttonStyle="secondary"
        disabled={!message || loading}
        onClick={handleClick}
      >
        <img src="/icons/send-icon.svg" alt="send" />
      </StyledButton>
      <Hint>shift + enter to send</Hint>
    </MessageInputContainer>
  );
};
