import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useAuth, useUser } from '../../context';
import {
  Button, Checkbox,
  CheckIcon,
  CoinIcon,
  CrossIcon,
  ErrorBlock,
  ESymbol,
  LoadingDots,
  PowerIcon,
  RefreshIcon
} from '../../components';
import { assetList } from '../AssetsPage/assetList';
import usePortal from 'react-cool-portal';
import countryList from 'react-select-country-list';
import { ChangePasswordModal, UpdatePasswordFormData } from './ChangePasswordModal';

const Page = styled.article`
  margin-bottom: 50px;
  margin-top: 50px;
  padding: 2.75rem;
  background: rgba(86, 94, 174, 0.5);
  backdrop-filter: blur(2px);
  border-radius: 25px;
  color: #f7f7f7;
  font-size: 1.5rem;

  a {
    text-decoration: none;

    :hover {
      color: #f60070;
    }
  }

  ${Button} {
    display: block;
    margin-bottom: 1rem;

    :last-of-type {
      margin-bottom: 2rem;
    }
  }

  ${ErrorBlock} {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    svg {
      margin-right: 0.5rem;
    }
  }
`;

const SuccessBlock = styled(ErrorBlock)`
  color: ${p => p.theme.colors.success};
`;

const AvatarBlock = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;

  div,
  p {
    margin: 0;
  }
`;

const H2 = styled.h2`
  font-size: 2.5rem;
  line-height: 1.5;
  margin-bottom: 0;
`;

const Avatar = styled.img`
  width: 11.25rem;
  height: 11.25rem;
  object-fit: cover;
  border-radius: 50%;
  background-color: ${p => p.theme.colors.secondary};
  margin-right: 4.5rem;
`;

const RefreshButton = styled(Button)`
  position: absolute;
  bottom: -28px;
  left: 4.4375rem;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  min-width: 1.5rem;
  padding: 10px;

  :focus {
    box-shadow: 0 0 0 1px #ffffff, 0 0 1px 3px #d1d5ff;
    outline: 1px dotted transparent;
  }
`;

const ActivityBox = styled.div`
  width: 100%;
  border-radius: 25px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
  font-size: 1.25rem;

  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
  background: inherit;
  position: relative;
  overflow: hidden;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 2000px rgba(209, 213, 255, 0.4);
    filter: blur(10px);
    //background: inherit;
  }

  & > p {
    color: #d1d5ff;
    margin: 0.3125rem 0 0.3125rem 3rem;

    :last-child {
      margin-bottom: 1rem;
    }
  }

  :last-child {
    margin-bottom: 0;
  }
`;

const ActivityTitle = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem 0.625rem 0;
  color: #d1d5ff;

  span {
    display: flex;
    align-items: center;
    font-weight: bold;

    p {
      margin-left: 1.375rem;
    }
  }
`;

const ActivityQuery = gql`
  query ActivityQuery {
    activity(period: DAY) {
      updatedAt
      code
      amount
      coins
    }
  }
`;

const UpdateUserMutation = gql`
  mutation UpadateUser($avatar: String!) {
    updateUser(avatar: $avatar) {
      avatar
    }
  }
`;

const UpdatePasswordMutation = gql`
  mutation UpdatePasswordMutation(
    $currentPassword: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    changePassword(
      currentPassword: $currentPassword
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      id
    }
  }
`;

const AllowMailMutation = gql`
  mutation AllowMail($enable: Boolean!) {
    allowMail(enable: $enable)
  }
`
export const ProfilePage = () => {
  // const location = useLocation();
  // console.log(location);
  const { Portal, show, hide, isShow } = usePortal({
    containerId: 'portal',
    defaultShow: false,
    internalShowHide: false
  });

  const user = useUser();
  const { signout } = useAuth();

  const { data } = useQuery(ActivityQuery, {
    fetchPolicy: 'cache-and-network'
  });

  const [
    updateUser,
    { loading: savingDetails, called }
  ] = useMutation(UpdateUserMutation, { refetchQueries: ['GetProfile'] });

  const [
    allowMail,
    { loading: allowingMail }
  ] = useMutation(AllowMailMutation, { refetchQueries: ['GetProfile'] });

  const [
    updatePassword,
    { data: updated, loading: saving, error }
  ] = useMutation(UpdatePasswordMutation);

  if (!user) return null;

  const activity = data?.activity ?? [];
  const { powerSpent, coinsReceived } = activity.reduce(
    (acc: any, entry: any) => {
      if (entry.coins) acc.coinsReceived.push(entry);
      else acc.powerSpent.push(entry);

      return acc;
    },
    { powerSpent: [], coinsReceived: [] }
  );
  const country = countryList().getLabel(user.country);

  const onSubmit = async (variables: UpdatePasswordFormData) => {
    try {
      await updatePassword({ variables });
    } catch (e) {
      console.log(e);
    } finally {
      hide();
    }
  };

  const updateAvatar = async () => {
    const peepNumber = Math.ceil(Math.random() * 105);
    try {
      await updateUser({
        variables: { avatar: `/peeps/peep-${peepNumber}.svg` }
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Page>
      <AvatarBlock>
        {!called && (
          <RefreshButton
            buttonStyle="medium"
            onClick={updateAvatar}
            disabled={savingDetails}
          >
            <RefreshIcon width={20} height={20} color="#f7f7f7" />
          </RefreshButton>
        )}
        <Avatar src={user.avatar || '/icons/user.svg'} alt="ava" />
        <div>
          <H2>{user.name}</H2>
          <p>{country}</p>
        </div>
      </AvatarBlock>
      <p>Name: {user.name}</p>
      <p>Country: {country}</p>
      <Button
        buttonStyle="medium"
        onClick={show}
        disabled={saving || updated || error}
      >
        {saving ? (
          <LoadingDots />
        ) : updated?.changePassword ? (
          'Updated!'
        ) : error ? (
          'Failed'
        ) : (
          'Change Password'
        )}
      </Button>
      {!!error && (
        <ErrorBlock>
          {error.graphQLErrors.map(({ message }, i) => (
            <>
              <CrossIcon width={20} height={20} />
              <span role="alert" key={i}>
                {message}
              </span>
            </>
          ))}
        </ErrorBlock>
      )}
      {!!updated && (
        <SuccessBlock>
          <CheckIcon width={20} height={20} />
          <span role="alert">
            Your password will be updated within 5 minutes
          </span>
        </SuccessBlock>
      )}
      <Button buttonStyle="medium" onClick={signout}>
        Logout
      </Button>

      <Checkbox
        id="allowMail"
        value={user.allowMail}
        disabled={allowingMail}
        onChange={(value) => allowMail({variables: {enable: value}})}>Yes, please email me my points and updates about new competitions (No more than 1 email per month)</Checkbox>

      <ActivityBox>
        <ActivityTitle>
          <span>
            <PowerIcon />
            <p>Power - {user.power}/10</p>
          </span>
          <p>Last 24 hours ago</p>
        </ActivityTitle>
        {powerSpent.map(({ code }: any, i: number) => (
          <p key={i}>
            2 Power spent on predicting price of {assetList[code]} ({code})
          </p>
        ))}
      </ActivityBox>

      <ActivityBox>
        <ActivityTitle>
          <span>
            <CoinIcon />
            <p>
              Ensydr score - {user.coins}
              <ESymbol color="#d1d5ff" />
            </p>
          </span>
          <p>Last 24 hours ago</p>
        </ActivityTitle>
        {coinsReceived.map(({ coins, code }: any, i: number) => (
          <p key={i}>
            {coins} points for predicting the price of {assetList[code]} ({code})
          </p>
        ))}
      </ActivityBox>

      <Portal>
        <ChangePasswordModal isShow={isShow} hide={hide} disabled={saving} onSubmit={onSubmit}/>
      </Portal>
    </Page>
  );
};
