import * as React from 'react';

export const CrossIcon = (props: any) => (
  <svg width={30} height={30} viewBox="0 0 30 30" {...props}>
    <g transform="translate(-10 -10)" fill="none" fillRule="evenodd">
      <path
        d="M36.027 11.027a2.083 2.083 0 112.946 2.946L27.946 25l11.027 11.027c.773.773.812 2.002.116 2.82l-.116.126a2.083 2.083 0 01-2.946 0L25 27.946 13.973 38.973a2.084 2.084 0 01-2.82.116l-.126-.116a2.083 2.083 0 010-2.946L22.054 25 11.027 13.973a2.084 2.084 0 01-.116-2.82l.116-.126a2.083 2.083 0 012.946 0L25 22.054z"
        fill={props.color || 'currentColor'}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
