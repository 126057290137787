export const weeklyContest = {
  type: 'score',
  startDate: '2021-11-15T00:00:00Z',
  endDate: '2021-12-15T23:59:59Z',
  prize: '1 Unique Ensydr Owl NFT',
  prizeImg: '/win/solana.png',
  image: '/win/Ensydr_Owl_NFT.png'
};

export const tournaments = [
  {
    dateTitle:
      'Predict price of AMC stock for the 21st June 2021 20:00 (GMT) Tournament - starts 9th June 2021',
    winTitle: 'Best Prediction wins 25 DogeCoins',
    image: '/win/amc_weekly.png',
    prizeImage: '/win/doge.png'
  },
  {
    dateTitle:
      'Score most Ensydr Points between 27 June - 3 July 2021 to win 5 Cardano (ADA)',
    winTitle: 'Make as many accurate predictions as possible everyday to win.',
    image: '/win/stonks-weekly.png',
    prizeImage: '/win/cardano.png'
  },
  {
    dateTitle:
      'Predict price of Bitcoin for the 5th July 2021 20:00 (GMT) Tournament - starts 21st June 2021',
    winTitle: 'Best prediction wins 0.5 Polkadot',
    image: '/win/BTC-041821.png',
    prizeImage: '/win/polkadot.png'
  },
  {
    dateTitle:
      'Score most Ensydr Points between 4 - 15 July 2021 to win 0.005 Ethereum (ETH)',
    winTitle: 'Make as many accurate predictions as possible everyday to win.',
    image: '/win/doge-weekly.png',
    prizeImage: '/win/eth.png'
  },
  {
    dateTitle: 'Predict the price of GameStop stock for 15th July 4:00pm (EDT)',
    winTitle: 'Best prediciton wins 25 Doge Coins.',
    image: '/win/gme_weekly.png',
    prizeImage: '/win/doge-pink.png'
  },
  {
    dateTitle:
      'Score most Ensydr Points between 18 - 29 July 2021 to win $20 Netflix voucher',
    winTitle: 'Make as many accurate predictions as possible everyday to win.',
    image: '/win/stonks-weekly.png',
    prizeImage: '/win/netflix.png'
  },
  {
    dateTitle:
      'Predict the price of Ethereum (ETH) for 31st July 4:00pm (EDT) to win',
    winTitle: 'Best prediction wins Gaming Headset',
    image: '/win/ETH-050621.png',
    prizeImage: '/win/headset.png'
  },
  {
    dateTitle:
      'Score most Ensydr Points between 9 - 20 August to win a Gaming Keyboard',
    winTitle: 'Make as many accurate predictions as possible everyday to win.',
    image: '/win/doge-weekly.png',
    prizeImage: '/win/keyboard.png'
  },
  {
    dateTitle:
      'Predict the price of DogeCoin (DOGE) for 15th Aug 4:00pm (EDT) to win',
    winTitle: 'Best prediction wins Gaming Mouse',
    image: '/win/doge-featured.png',
    prizeImage: '/win/mouse.png'
  }
];

export const prizes = [
  {
    image: '/win/doge.png',
    title: '25 Doge Coins',
    condition: 'Predict price of AMC'
  },
  {
    image: '/win/voucher.png',
    title: '$20 Amazon Gift card',
    condition: 'Most points collected between 27 June - 3 July'
  },
  {
    image: '/win/polkadot.png',
    title: 'Polkadot DOT',
    condition: 'Predict price of BTC'
  },
  {
    image: '/win/eth.png',
    title: '0.005 Ethereum ETH',
    condition: 'Most points collected between 4th - 15th July'
  },
  {
    image: '/win/doge-pink.png',
    title: '25 Doge Coins',
    condition: 'Predict price of GME'
  },
  {
    image: '/win/netflix.png',
    title: '$20 Netflix voucher',
    condition: 'Most points collected 16th - 25th July'
  },
  {
    image: '/win/headset.png',
    title: 'Gaming headset',
    condition: 'Predict the price of ETH'
  },
  {
    image: '/win/keyboard.png',
    title: 'Gaming keyboard',
    condition: 'Most points collected between 9 Aug - 20 Aug'
  },
  {
    image: '/win/mouse.png',
    title: 'Gaming mouse',
    condition: 'Predict price of Doge'
  }
];
