import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split
} from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

// @ts-ignore
import { hasSubscription } from '@jumpn/utils-graphql';

import absintheSocketLink from './absinthe-socket-link';

// @ts-ignore
const link = new split(
  (op: any) => hasSubscription(op.query),
  absintheSocketLink,
  createHttpLink({ uri: process.env.REACT_APP_API_URL, credentials: 'include' })
);

export default new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          allActivity: relayStylePagination(),
          news: relayStylePagination()
        }
      }
    }
  })
});
