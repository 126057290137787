import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { format, isAfter, parseISO } from 'date-fns';

import { Button } from '../Button/Button';
import { LoadingDots } from '../LoadingDots/LoadingDots';
import { InfoSubtitle } from './BidPanel';
import { useUser } from '../../context';

const Form = styled.form`
  width: 100%;

  ${Button} {
    height: 2.75rem;
  }
`

export const Input = styled.input`
  font-size: 2.5rem;
  line-height: 3rem;
  background: #fdfdfd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border: 0;
  border-radius: 3px;
  max-width: 100%;
  margin-bottom: 0.625rem;

  ::placeholder {
    color: #cbcbcb;
  }
`;

type Props = {
  contest: any;
  submitting: boolean;
  onSubmit: (input: any) => Promise<void>;
}

export const BidInput = ({ contest, submitting, onSubmit }: Props) => {
  const user = useUser();
  const { register, handleSubmit } = useForm();

  const { asset, closeDate, endDate } = contest ?? {};
  const { price } = asset ?? {};

  const closed = isAfter(Date.now(), parseISO(closeDate));
  const disabled = submitting || closed || user?.power < 2;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {!user || user.power > 1 ? (
        <InfoSubtitle>
          {closed ? 'Sorry, prediction for' : 'Predict the price on'}
          <br /> {format(parseISO(endDate), 'PPp')}
          {closed && (
            <>
              <br /> is closed
            </>
          )}
        </InfoSubtitle>
      ) : (
        <InfoSubtitle>
          Sorry, you've got not enough power to make a prediction.<br />
          The power is refilled in 24h after your prediction
        </InfoSubtitle>
      )}
      <Input
        type="number"
        placeholder={price > 1 ? '$1000.00 USD' : '$0.00000 USD'}
        step={price > 1 ? '.01' : '0.00001'}
        autoFocus
        disabled={disabled}
        {...register('price', { required: true })}
      />
      <Button buttonStyle="success" type="submit" disabled={disabled}>
        {submitting ? <LoadingDots /> : 'Submit'}
      </Button>
    </Form>
  )
}
