import styled from 'styled-components';
import { useUser } from '../../context';
import React from 'react';

export const Entry = styled.article`
  margin-bottom: 1.875rem;
  color: #f7f7f7;

  p {
    margin: 0 0 0.3125rem;
  }
`;

export const EntryTitle = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem 0.625rem 0;
  color: #d1d5ff;

  span {
    display: flex;
    align-items: center;
    font-weight: bold;

    p {
      margin-left: 1.375rem;
    }

    img {
      height: 42px;
      width: 42px;
      object-fit: cover;
      border-radius: 50%;
      background-color: ${p => p.theme.colors.secondary};
    }
  }
`;

type ActivityEntryProps = {
  user: any,
  at: string,
  activities: Array<{
    amount: number,
    asset: string,
    text: string,
    endDate: string,
    finalPrice: number;
  }>
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

export const ActivityEntry = ({user, at, activities}: ActivityEntryProps) => {
  const currentUser = useUser();

  return (
    <Entry>
      <EntryTitle>
          <span>
            <img
              src={(user || currentUser).avatar || '/icons/user.svg'}
              alt="avatar"
            />
            <p>{(user || currentUser).name}</p>
          </span>
        <p>{at} ago</p>
      </EntryTitle>
      {activities.map(
        (
          {amount, asset, text, endDate, finalPrice}: any,
          i: number
        ) => (
          <p key={i}>
            {text}
            <strong>{amount}</strong> on {asset} for {endDate}
            {!!finalPrice && (
              <i>
                {' '}
                (
                {finalPrice > 1
                  ? formatter.format(finalPrice)
                  : `$${finalPrice}`}{' '}
                market price)
              </i>
            )}
          </p>
        )
      )}
    </Entry>
  )
}
