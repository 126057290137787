import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import ReactGA from 'react-ga';

import {
  BidPanel,
  Header,
  useNoUserModal,
  useSuccessBidModal
} from '../../components';
import { useUser } from '../../context';

import { ListContests, PlaceBid } from './queries';
import { ContestActivity } from './ContestActivity';

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 800px;

  @media (min-width: 640px) {
    text-align: left;
  }

  @media (min-width: 1024px) {
    margin: auto 5.125rem;
  }
  h3 {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 1.875rem;
    line-height: 2.3125rem;
    color: #f7f7f7;
  }
`;

export const BidPage = () => {
  const { code: codeParam } = useParams();
  const code = codeParam.split('-').join('/');
  const user = useUser();
  const { data, error, loading } = useQuery(ListContests, {
    variables: { code },
    fetchPolicy: 'cache-and-network'
  });
  const [placeBid, mutation] = useMutation(PlaceBid, {
    refetchQueries: ['listContests']
  });

  const contest =
    data?.listContests.find((c: any) => c.type === 'featured') ??
    data?.listContests?.[0] ??
    {};

  const { asset = {}, id, type: contestType } = contest;
  const { name } = asset;

  const { SuccessBidModal, showSuccessBidModal } = useSuccessBidModal();
  const { NoUserModal, showNoUserModal } = useNoUserModal();

  const submitPrediction = async ({ price }: any) => {
    try {
      if (user) {
        await placeBid({ variables: { id, code, price } });
        showSuccessBidModal();
        ReactGA.event({
          category: 'User',
          action: 'Place bid'
        });
      } else {
        showNoUserModal();
      }
    } catch (err) {
      // TODO: create a modal for generic error
      showNoUserModal();
    }
  };

  return (
    <StyledSection>
      <Header>
        {name} ({code})
      </Header>

      <BidPanel
        image={`/assets/${codeParam}${
          contestType === 'daily' ? '' : `-${contestType}`
        }.png`}
        contest={contest}
        loading={loading}
        onSubmit={submitPrediction}
        submitting={mutation.loading}
        error={error}
      />

      <ContestActivity code={code} />

      {contestType === 'daily' && (
        <h3>Get 100 ENSYDR points for the right prediction</h3>
      )}

      <SuccessBidModal bid={mutation.data?.placeBid?.bid ?? 0} asset={asset} />
      <NoUserModal />
    </StyledSection>
  );
};
