import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, ErrorBlock, Form, LoadingDots } from '../../components';
import { useUser } from '../../context';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

type ForgotFormData = {
  email: string;
};

const ResetPassword = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email) {
      email
    }
  }
`;

export const ForgotForm = () => {
  const { register, handleSubmit } = useForm<ForgotFormData>();
  const [reset, { loading, data, error }] = useMutation(ResetPassword);
  const navigate = useNavigate();
  const user = useUser();
  useEffect(() => {
    if (user) navigate('/assets');
  }, [user, navigate]);
  const onSubmit = ({ email }: ForgotFormData) =>
    reset({ variables: { email } });

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit as any)}>
        {data?.resetPassword?.email ? (
          <label>If you registered, you should receive a reset link</label>
        ) : (
          <>
            <label htmlFor="email">We'll resend a recovery link to</label>
            <input
              type="email"
              aria-required="true"
              autoComplete="username"
              autoFocus
              {...register('email', { required: true })}
            />
            <div>
              <Button buttonStyle="secondary" type="submit" disabled={loading}>
                {loading ? <LoadingDots /> : 'Send'}
              </Button>
            </div>
          </>
        )}
      </Form>
      {!!error && (
        <ErrorBlock>
          {error.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </ErrorBlock>
      )}
    </>
  );
};
