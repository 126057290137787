import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Button, ButtonRow, Form as FormComponent, Modal as ModalComponent } from '../../components';

const Form = styled(FormComponent)`
  text-align: left;
  margin: 0 auto;
  width: 90%;

  input {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

    ::placeholder {
      color: #bfbfbf;
    }

    &[aria-invalid='true'] {
      border-bottom: 2px solid ${p => p.theme.colors.alert};
    }

    &[aria-invalid='false'].touched {
      border-bottom: 2px solid ${p => p.theme.colors.success};
    }
  }

  ${ButtonRow} {
    margin-top: 2rem;
  }
`;

const Modal = styled(ModalComponent)``;

export type UpdatePasswordFormData = {
  password: string;
  currentPassword: string;
  passwordConfirmation: string;
};

type Props = {
  isShow: boolean;
  hide: () => void;
  disabled: boolean;
  onSubmit: (value: UpdatePasswordFormData) => void;
}

export const ChangePasswordModal = ({isShow, hide, disabled, onSubmit}: Props) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { touchedFields, errors },
  } = useForm<UpdatePasswordFormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange'
  });
  return (
    <Modal type="success" hide={false} isShow={isShow}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="currentPassword">Current Password</label>
        <input
          type="password"
          aria-required="true"
          autoComplete="current-password"
          aria-invalid={!!errors.currentPassword}
          className={touchedFields.currentPassword ? 'touched' : ''}
          {...register('currentPassword', { required: true })}
        />
        <label htmlFor="password">New Password</label>
        <input
          type="password"
          aria-required="true"
          autoComplete="off"
          aria-invalid={!!errors.password}
          className={touchedFields.password ? 'touched' : ''}
          placeholder="Min 8 characters"
          {...register('password', { required: true, minLength: 8 })}
        />
        <label htmlFor="passwordConfirmation">Password Confirmation</label>
        <input
          type="password"
          aria-required="true"
          autoComplete="off"
          aria-invalid={!!errors.passwordConfirmation}
          className={touchedFields.password ? 'touched' : ''}
          placeholder="Re-enter password"
          {...register('passwordConfirmation', {
            validate: value => value === getValues()['password']
          })}
        />
        <ButtonRow>
          <Button shape="sharp" buttonStyle="ghost" onClick={hide}>
            Close
          </Button>
          <Button
            shape="sharp"
            buttonStyle="primary"
            type="submit"
            disabled={disabled}
          >
            Submit
          </Button>
        </ButtonRow>
      </Form>
    </Modal>
  )
}
