import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

const RibbonBase = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scrollbar-width: thin;
  scrollbar-color: #d1d5ff transparent;

  ::-webkit-scrollbar {
    height: 0.375rem;
    width: 2rem;
  }
  ::-webkit-scrollbar-thumb {
    background: #d1d5ff;
    border-radius: 5px;
  }
`;
const AssetCard = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 1rem;
  overflow: hidden;
  position: relative;
  height: 12.5rem;
  min-width: 9.25rem;
  max-width: 9.25rem;
  border-radius: 5px;

  &:hover img {
    transform: scale(1.05);
  }

  img {
    object-fit: contain;
    max-height: 100%;
    transform: scale(1.3);
  }
  div {
    display: flex;
    text-align: left;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 70%;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.5rem;

    color: #f7f7f7;
    background: linear-gradient(
      1.15deg,
      rgba(0, 0, 0, 0.9) 0.69%,
      rgba(86, 94, 174, 0) 99.27%
    );
    align-items: flex-end;
    padding: 2rem 0.5rem 0.5rem;
  }
`;

const AssetsQuery = gql`
  query openAssets {
    assets {
      code
      name
      status
    }
  }
`;

export const AssetsRibbon = () => {
  const { data, loading, error } = useQuery<{ assets: any }>(AssetsQuery, {
    fetchPolicy: 'cache-and-network'
  });

  if (loading || error) return null;

  const assets = data?.assets ?? [];

  return (
    <RibbonBase>
      {assets.map(({ name, code }: any) => {
        const codeDashed = code.split('/').join('-');
        return (
          <AssetCard key={code}>
            <Link to={`/bid/${codeDashed}`}>
              <img src={`/assets/${codeDashed}.png`} alt={name} />
              <div>
                <p>
                  {name} ({code})
                </p>
              </div>
            </Link>
          </AssetCard>
        );
      })}
    </RibbonBase>
  );
};
