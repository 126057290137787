import * as React from 'react';

export const RefreshIcon = (props: any) => (
  <svg width={48} height={46} viewBox="0 0 48 46" {...props}>
    <g transform="matrix(-1 0 0 1 47.917 -2)" fill="none" fillRule="evenodd">
      <path
        d="M45.833 29.167c.875 0 1.624.539 1.933 1.302l.01.027c.095.244.141.501.14.759V43.75a2.083 2.083 0 01-4.166 0v-7.684l-6.11 5.74a20.833 20.833 0 01-34.375-7.778 2.083 2.083 0 113.928-1.39 16.667 16.667 0 0012.034 10.696c5.582 1.263 11.42-.426 15.513-4.519l5.834-5.482h-7.24a2.083 2.083 0 010-4.166h12.5zM29.61 2.597a20.833 20.833 0 0115.042 13.37 2.083 2.083 0 11-3.927 1.39A16.667 16.667 0 0028.69 6.661c-5.582-1.263-11.421.426-15.513 4.52l-5.834 5.481h7.24a2.083 2.083 0 110 4.167h-12.5a2.084 2.084 0 01-1.932-1.303l-.01-.026A2.085 2.085 0 010 18.74V6.246a2.083 2.083 0 014.167 0v7.685l6.11-5.74a20.833 20.833 0 0119.332-5.593z"
        fill={props.color || '#000'}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
