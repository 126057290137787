import * as React from 'react';

export const BarChartIcon = (props: any) => (
  <svg width={46} height={42} viewBox="0 0 46 42" {...props}>
    <g transform="translate(-2 -4)" fill="none" fillRule="evenodd">
      <path
        d="M22.917 8.333v33.334h4.166V8.333h-4.166zm6.25-4.166c1.15 0 2.083.932 2.083 2.083v37.5c0 1.15-.933 2.083-2.083 2.083h-8.334a2.083 2.083 0 01-2.083-2.083V6.25c0-1.15.933-2.083 2.083-2.083h8.334zm16.666 10.416c1.15 0 2.084.933 2.084 2.084V43.75c0 1.15-.933 2.083-2.084 2.083H37.5a2.083 2.083 0 01-2.083-2.083V16.667c0-1.15.932-2.084 2.083-2.084h8.333zM12.5 25c1.15 0 2.083.933 2.083 2.083V43.75c0 1.15-.932 2.083-2.083 2.083H4.167a2.083 2.083 0 01-2.084-2.083V27.083c0-1.15.933-2.083 2.084-2.083H12.5zm31.25-6.25h-4.167v22.917h4.167V18.75zM10.417 29.167H6.25v12.5h4.167v-12.5z"
        fill={props.color || 'currentColor'}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
