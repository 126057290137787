import * as React from 'react';
import styled from 'styled-components';

const BookmarkSvg = ({ rank, ...props }: any) => {
  return (
    <svg width={50} height={50} fill="none" viewBox="0 0 50 50" {...props}>
      <path fill="#EF0E74" d="M12 7h26v34l-13-8.5L12 41V7z" />
      <mask
        id="prefix__a"
        width={34}
        height={42}
        x={8}
        y={4}
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M11.628 45.445L25 35.894l13.373 9.551c1.379.985 3.294 0 3.294-1.695V10.417a6.25 6.25 0 00-6.25-6.25H14.583a6.25 6.25 0 00-6.25 6.25V43.75c0 1.694 1.916 2.68 3.295 1.695zM37.5 10.417v29.285l-11.289-8.064a2.083 2.083 0 00-2.422 0L12.5 39.702V10.417c0-1.15.933-2.084 2.084-2.084h20.833c1.15 0 2.083.933 2.083 2.084z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          fill="#EF0E74"
          stroke="#EF0E74"
          strokeWidth={10}
          d="M0 0h50v50H0z"
        />
      </g>
      <text
        x={18}
        y={30}
        style={{ fill: '#fff', fontSize: 25, fontWeight: 'bold', zIndex: 100 }}
      >
        {rank}
      </text>
    </svg>
  );
};

export const Bookmark = styled(BookmarkSvg)``;
