import React from 'react';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import ReactGA from 'react-ga';

import { Button, Header, LoadingDots } from '../../components';
import { useUser } from '../../context';

const Page = styled.section`
  display: flex;
  flex-direction: column;
  color: #fff;
  padding-top: 40px;

  @media (min-width: 640px) {
    padding-top: 90px;
    padding-left: 30px;
    flex-direction: row;

    form + img {
      display: none;
    }
  }

  p {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #f7f7f7;
    margin-top: 0;
  }
`;

const ContactImage = styled.img.attrs(() => ({
  src: '/shapes/contact.svg',
  alt: 'Contact'
}))`
  margin-bottom: 80px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;

  @media (min-width: 800px) {
    margin-right: 90px;
    width: 40%;
  }

  ${ContactImage} {
    display: none;

    @media (min-width: 640px) {
      display: block;
    }
  }
`;

const inputStyles = css`
  background: #fdfdfd;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  border: none;
  border-radius: 3px;
  max-width: 100%;
  padding: 1rem 0.5625rem;
  margin-bottom: 1.875rem;
  font-family: Helvetica, sans-serif;
  font-size: 15px;
  line-height: 17px;
  color: #252525;
`;

const Input = styled.input`
  ${inputStyles}
`;

const Textarea = styled.textarea`
  ${inputStyles}
`;

const Label = styled.label`
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #f7f7f7;
  margin-bottom: 6px;

  &:after {
    content: '*';
    color: ${p => p.theme.colors.primary};
    margin-left: 3px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 50%;

  ${Button} {
    align-self: flex-end;
    margin-bottom: 50px;
  }

  img {
    max-width: 100%;
  }
`;

type ContactFormData = {
  name: string;
  email: string;
  message: string;
};

const ContactMutation = gql`
  mutation ContactMutation($contactRequest: ContactRequest!) {
    submitRequest(contactRequest: $contactRequest) {
      sent
    }
  }
`;

export const ContactPage = () => {
  const { register, handleSubmit } = useForm<ContactFormData>();
  const user = useUser();
  const [contact, { loading, data, error }] = useMutation(ContactMutation);
  const onSubmit = async (data: ContactFormData) => {
    try {
      await contact({ variables: { contactRequest: data } });
    } catch (err) {
      console.log(err);
    }
  };

  // TODO: prettify info/error messages
  return (
    <Page>
      <Column>
        <Header>Contact Us</Header>
        <p>
          Got a question or need something clarified? Fill out the contact form
          and we'll get back to you as soon as possible.
        </p>
        <ContactImage />
      </Column>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <a
          href="https://discord.gg/N9zXXn4FnU"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            ReactGA.event({
              category: 'Social',
              action: 'Click Join Discord'
            });
          }}
        >
          <img src="/social/discord-join.png" alt="Discord" />
        </a>
        <h2>OR write your message here:</h2>
        <Label htmlFor="name">Name</Label>
        <Input
          defaultValue={user?.name}
          type="text"
          {...register('name', { required: true })}
        />
        <Label htmlFor="email">Email</Label>
        <Input
          defaultValue={user?.email}
          type="text"
          {...register('email', { required: true })}
        />
        <Label htmlFor="message">Message</Label>
        <Textarea rows={5} {...register('message', { required: true })} />
        {data?.submitRequest?.sent ? (
          'Thank you! We received your message.'
        ) : error ? (
          'Sorry, something went wrong...'
        ) : (
          <Button buttonStyle="primary" type="submit" disabled={loading}>
            {loading ? <LoadingDots /> : 'Submit'}
          </Button>
        )}
      </Form>
      <ContactImage />
    </Page>
  );
};
