import styled from 'styled-components';

export const H2 = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  margin-bottom: 0.5rem;

  color: #d1d5ff;
`;
